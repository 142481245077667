import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useEffect, useRef, useState } from 'react';
import dailyPickStore from '@store/daily-pick-store.ts';
import { Link } from 'react-router-dom';
import { FullExperience } from '@/schemas/experience-schema.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import getOrdinalSuffix from '@utils/get-ordinal-suffix.ts';
import Button from '@components/core/button/button.tsx';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import PlaceBanner from '@components/data/place-banner/place-banner.tsx';
import { PlaceBannerSize } from '@components/data/place-banner/place-banner-constants.ts';

const dailyPickReleaseDate = new Date('2024-09-20');

const loadDailyPicks = async (startDate: Date, endDate: Date) => {
	await dailyPickStore.loadDailyPicks(startDate, endDate);
	const expIds: number[] = [];
	const picks = dailyPickStore.getDailyPicks(startDate, endDate);
	for (const pick of picks) {
		pick.places.forEach((exp) => {
			expIds.push(exp.place_id);
		});
	}
	await thumbnailStore.loadThumbnailsById(expIds, ThumbnailType.BANNER);
};

const isTodayOrAfter = (date: Date) => {
	const today = new Date();
	const thisYearOrLater = date.getFullYear() >= today.getFullYear();
	const thisMonthOrLater =
		thisYearOrLater && date.getMonth() >= today.getMonth();
	const thisDateOrLater =
		thisMonthOrLater && date.getDate() >= today.getDate();

	return thisYearOrLater && thisMonthOrLater && thisDateOrLater;
};

const releaseMonthOrEarlier = (date: Date) => {
	return (
		date.getFullYear() <= dailyPickReleaseDate.getFullYear() &&
		date.getMonth() <= dailyPickReleaseDate.getMonth()
	);
};

const currentMonthOrLater = (date: Date) => {
	const today = new Date();
	return (
		date.getFullYear() >= today.getFullYear() &&
		date.getMonth() >= today.getMonth()
	);
};

const retrievalStartDate = (date: Date) => {
	if (date.getTime() <= dailyPickReleaseDate.getTime()) {
		return dailyPickReleaseDate.getDate();
	}

	return date.getDate();
};

const afterToday = (date: Date) => {
	const today = new Date();
	today.setHours(23);
	today.setMinutes(59);
	today.setSeconds(59);
	return date.getTime() >= today.getTime();
};

const formatDateWithOrdinal = (date: Date): string => {
	const options: Intl.DateTimeFormatOptions = {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	};
	const dateParts = date.toLocaleDateString('en-US', options).split(' ');

	// Extract the day and add the ordinal suffix
	const day = parseInt(dateParts[1], 10);
	const dayWithOrdinal = day + getOrdinalSuffix(day);

	return `${dateParts[0]} ${dayWithOrdinal}`;
};

const DailyPicks = observer(function DailyPicks() {
	const ref = useRef<HTMLDivElement>(null);

	const today = new Date();
	const [date, setDate] = useState(
		new Date(today.getFullYear(), today.getMonth(), 1, 2, 1, 0, 0)
	);
	const lastDayOfMonth = new Date(
		today.getFullYear(),
		today.getMonth() + 1,
		0,
		21,
		59,
		59,
		999
	);

	const picks = dailyPickStore.getDailyPicks(
		new Date(
			date.getFullYear(),
			date.getMonth(),
			retrievalStartDate(date),
			2,
			0,
			0
		),
		new Date(
			date.getFullYear(),
			afterToday(lastDayOfMonth) ? date.getMonth() : date.getMonth() + 1,
			afterToday(lastDayOfMonth) ? today.getDate() : 0,
			21,
			59,
			59,
			999
		)
	);

	useEffect(() => {
		void loadDailyPicks(
			new Date(
				date.getFullYear(),
				date.getMonth(),
				retrievalStartDate(date),
				2,
				0,
				0
			),
			new Date(
				date.getFullYear(),
				afterToday(lastDayOfMonth)
					? date.getMonth()
					: date.getMonth() + 1,
				afterToday(lastDayOfMonth) ? today.getDate() : 0,
				21,
				59,
				59,
				999
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	const previousMonth = () => {
		setDate(
			new Date(date.getFullYear(), date.getMonth() - 1, 1, 2, 0, 0, 0)
		);
	};

	const nextMonth = () => {
		setDate(
			new Date(date.getFullYear(), date.getMonth() + 1, 1, 2, 0, 0, 0)
		);
	};

	return (
		<View
			viewInfo={{
				title: 'Daily Picks',
				icon: IconEnum.DAILY_PICKS,
			}}
			hideHeader
		>
			<ViewPanel className={'max-w-screen-md'}>
				<div
					className={
						'flex justify-evenly items-center w-full p-4 gap-12'
					}
				>
					<div className={'text-center'}>
						{
							'Daily Picks are a curated selection of daily highlights by Roblox staff. Certain periods might be missing daily picks due to events such as "The Haunt" or "Winter Spotlight".'
						}
					</div>
				</div>
			</ViewPanel>

			<div
				className={'w-full flex justify-between items-center gap-4'}
				ref={ref}
			>
				<Button
					title={'Previous Month'}
					disabled={releaseMonthOrEarlier(date)}
					onClick={previousMonth}
				/>

				<div
					className={'text-3xl font-medium'}
				>{`Daily Picks ${date.toLocaleString('en-US', { month: 'long' })} ${date.getFullYear()}`}</div>

				<Button
					title={'Next Month'}
					disabled={currentMonthOrLater(date)}
					onClick={nextMonth}
				/>
			</div>

			{picks.map((pick, index) => {
				return (
					<ViewPanel key={`pick-${index}`}>
						<div className={'w-full flex-col'}>
							<div
								className={
									'text-2xl mb-4 flex justify-start items-center w-full'
								}
							>
								{`${formatDateWithOrdinal(pick.date)}`}
							</div>

							{!!pick.places.length && (
								<div
									className={
										'grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-center items-start gap-8'
									}
								>
									{pick.places.map((exp) => {
										const slimExp = exp as FullExperience;

										return (
											<div
												className={
													'max-w-full flex flex-col justify-center items-center'
												}
												key={`daily-pick-${exp?.place_id}`}
											>
												<Link
													to={`/experiences/${exp?.place_id}`}
													className={
														'flex flex-col justify-center items-center gap-2 hover:bg-blue-500 hover:text-white w-fit p-2 rounded-lg group'
													}
													draggable={false}
												>
													<div
														className={
															'rounded-md outline outline-1 outline-gray-600 group-hover:outline-gray-300 dark:outline-gray-500 overflow-hidden'
														}
													>
														<PlaceBanner
															place={slimExp}
															size={
																PlaceBannerSize.MEDIUM
															}
														/>
													</div>

													<div
														className={
															'w-full flex justify-center items-center text-center'
														}
													>
														{exp?.name}
													</div>
												</Link>
											</div>
										);
									})}
								</div>
							)}

							{!pick.places.length && (
								<div
									className={
										'w-full h-20 flex justify-center items-center'
									}
								>
									{isTodayOrAfter(pick.date)
										? 'No New Daily Picks Yet'
										: 'No Daily Picks'}
								</div>
							)}
						</div>
					</ViewPanel>
				);
			})}

			<div className={'w-full flex justify-between items-center gap-4'}>
				<Button
					title={'Previous Month'}
					disabled={releaseMonthOrEarlier(date)}
					onClick={() => {
						previousMonth();
						ref.current?.scrollIntoView();
					}}
				/>

				<Button
					title={'Next Month'}
					disabled={currentMonthOrLater(date)}
					onClick={() => {
						nextMonth();
						ref.current?.scrollIntoView();
					}}
				/>
			</div>
		</View>
	);
});

export default DailyPicks;
