class ValidationService {
	private isNumberRegEx = new RegExp(/^\d+$/);
	private validEmailRegEx = new RegExp(/.+@.+\..+/);

	databaseId(id: string | number): boolean {
		let numberId = id;

		if (typeof id === 'string' && this.isNumberRegEx.test(id)) {
			numberId = +id;
		} else {
			return false;
		}

		return Number.isInteger(numberId) && +numberId > 0;
	}

	number(number: unknown) {
		return typeof number === 'string' && this.isNumberRegEx.test(number);
	}

	email(email: string) {
		return this.validEmailRegEx.test(email);
	}
}

const validate = new ValidationService();
export default validate;
