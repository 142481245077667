import type { Config } from 'tailwindcss';

const config: Config = {
	content: [
		'./src/components/**/*.{js,ts,jsx,tsx,mdx}',
		'./src/pages/**/*.{js,ts,jsx,tsx,mdx}',
		'./src/services/**/*.{js,ts,jsx,tsx,mdx}',
		'./src/views/**/*.{js,ts,jsx,tsx,mdx}',
		'./src/app.tsx',
	],
	darkMode: 'selector',
	theme: {
		fontFamily: {
			inter: ['Inter', 'sans-serif'],
		},
		colors: {
			transparent: 'transparent',
			black: '#0c0c0c',
			white: '#ffffff',
			'blue-silver': '#8A99AF',
			'light-black': '#212427',

			'gray-100': '#f6f8f7',
			'gray-200': '#e1e1e1',
			'gray-300': '#c2c2c2',
			'gray-400': '#a4a4a4',
			'gray-500': '#676767',
			'gray-525': '#575757',
			'gray-550': '#464646',
			'gray-575': '#363636',
			'gray-600': '#252525',

			'yellow-100': '#ffffce',
			'yellow-200': '#feff83',
			'yellow-300': '#feff3a',
			'yellow-400': '#fff731',
			'yellow-500': '#ffe600',
			'yellow-600': '#ead300',

			'blue-100': '#b8e5ff',
			'blue-200': '#96d9ff',
			'blue-300': '#73ccff',
			'blue-400': '#6d74fd',
			'blue-500': '#3c45fe',
			'blue-525': '#2E36E5',
			'blue-550': '#1F27CD',
			'blue-575': '#1118B4',
			'blue-600': '#02099b',

			'green-100': '#edfec5',
			'green-200': '#dafe8d',
			'green-300': '#c8fd54',
			'green-400': '#b5fc1b',
			'green-500': '#8ecf06',
			'green-600': '#476700',

			'red-100': '#fedee4',
			'red-200': '#ffd4dc',
			'red-300': '#fea9b7',
			'red-400': '#fe7d95',
			'red-500': '#ff2e54',
			'red-525': '#E42840',
			'red-550': '#C9222D',
			'red-575': '#AD1B19',
			'red-600': '#921505',

			'pink-100': '#ffe9fc',
			'pink-200': '#ffdcfb',
			'pink-300': '#ffc2f8',
			'pink-400': '#ff86f0',
			'pink-500': '#ff04e2',
			'pink-600': '#ae009a',
		},
		screens: {
			sm: '640px',
			md: '760px',
			lg: '960px',
			xl: '1160px',
			'2xl': '1536px',
		},
		extend: {
			backgroundImage: {
				'watermark-black': 'url("assets/logo_watermark_black.png")',
				'watermark-white': 'url("assets/logo_watermark_white.png")',
			},
			borderWidth: {
				'12': '12px',
			},
			boxShadow: {
				outline: '0 1px #252525',
				'outline-dark': '0 1px #676767',
				focus: 'inset 0 0 0 2px #3c45fe',
				'focus-dark': 'inset 0 0 0 2px #b8e5ff',
				anchor: '0 0 15px 5px #3c45fe;',
				'anchor-dark': '0 0 15px 5px #6d74fd;',
			},
			margin: {
				'toast-fade-out': '-400px',
				nav: '70px',
			},
			transitionProperty: {
				'toast-fade-out': 'margin',
				select: 'max-height, opacity',
				height: 'height',
			},
			borderRadius: {
				button: '17px', // Half button height
			},
			height: {
				button: '34px',
				'expansion-input-open': 'calc(34px * var(--button-count))',
				'expansion-button-open':
					'min(calc(34px * var(--button-count) + 34px), calc(34px * 10 + 34px))',
				'expansion-panel-open':
					'calc(var(--panel-height) + var(--title-height))',
				nav: '70px',
				'scroll-container': 'calc(100dvh - 70px)', // Full height minus nav bar
				'dynamic-screen': '100dvh',
			},
			minHeight: {
				'expansion-panel-title': 'var(--title-height)',
			},
			inset: {
				sidebar: '290px',
			},
			width: {
				button: '34px',
				sidebar: '290px',
				nav: 'calc(100vw - 290px)',
				'scroll-container': 'calc(100vw - 84px)',
				'scroll-container-expanded': 'calc(100vw - 290px)',
				'minimal-nav': 'calc(100vw - 6rem)',
				'experience-thumbnail': '280px',
				'panel-1/2': 'calc(50% - 1rem)',
				'panel-1/3': 'calc(33.333333% - 1rem)',
				'tools-logo': 'var(--tools-logo-width)',
			},
			opacity: {
				2: '0.02',
				3: '0.03',
			},
			padding: {
				sidebar: '290px',
				nav: '70px',
			},
			minWidth: {
				'experience-thumbnail': '280px',
			},
			maxWidth: {
				'experience-thumbnail': '280px',
			},
			animation: {
				'options-menu': 'open-options-menu 100ms forwards',
				'hide-tab': 'hide-tab 400ms forwards',
				'tooltip-show': 'tooltip-show 100ms forwards',
				'tooltip-hide': 'tooltip-hide 200ms forwards',
			},
			keyframes: {
				'open-options-menu': {
					'0%': { height: '0' },
					'100%': { height: 'calc(var(--button-count) * 34px)' },
				},
				'hide-tab': {
					'0%': { visibility: 'visible' },
					'99%': { visibility: 'visible' },
					'100%': { visibility: 'hidden' },
				},
				'tooltip-show': {
					'0%': { opacity: '0' },
					'100%': { opacity: '1' },
				},
				'tooltip-hide': {
					'0%': { opacity: '1' },
					'50%': { opacity: '1' },
					'100%': { opacity: '0' },
				},
			},
			animationTimingFunction: {
				'transition-curve': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
			},
			transitionTimingFunction: {
				'transition-curve': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
			},
			zIndex: {
				'in-front': '2',
				'in-front-2': '3',
				'in-front-3': '4',
				'in-front-4': '5',
				'nav-menu': '8',
				nav: '9',
				'nav-sidebar': '10',
				'modal-overlay': '51',
				modal: '52',
				toast: '53',
			},
		},
	},
	plugins: [],
};
export default config;
