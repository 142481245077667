import { observer } from 'mobx-react-lite';
import { ExperienceRanking } from '@/schemas/experience-rating.ts';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { ValueFormatterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import { RankingCategories } from '@/enums/ranking-categories.ts';

const formatDurationString = (durationMillis: number) => {
	const millisecondsInAMinute = 1000 * 60;
	const millisecondsInAnHour = millisecondsInAMinute * 60;
	const millisecondsInADay = millisecondsInAnHour * 24;

	const totalDays = Math.floor(durationMillis / millisecondsInADay);
	const remainingMillisecondsAfterDays = durationMillis % millisecondsInADay;

	const totalHours = Math.floor(
		remainingMillisecondsAfterDays / millisecondsInAnHour
	);
	const remainingMillisecondsAfterHours =
		remainingMillisecondsAfterDays % millisecondsInAnHour;

	const totalMinutes = Math.floor(
		remainingMillisecondsAfterHours / millisecondsInAMinute
	);

	let durationString = '';

	if (totalDays > 0) {
		durationString = `${totalDays} day${totalDays === 1 ? '' : 's'}`;
	}

	if (totalHours > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalHours} hour${totalHours === 1 ? '' : 's'}`;
	}

	if (totalMinutes > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalMinutes} min`;
	}

	return durationString;
};

interface ExperienceRankingEntry extends ExperienceRanking {
	num: number;
	totalTime: number;
}

const ColumnDefs: CustomColDef<ExperienceRankingEntry>[] = [
	{
		headerName: 'Category',
		field: 'y',
		valueFormatter: (
			params: ValueFormatterParams<ExperienceRankingEntry, number>
		) => {
			const y = params.value!;
			return RankingCategories[y].title ?? 'Unknown Category';
		},
		minWidth: 150,
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
		flex: 1,
	},
	{
		headerName: 'Peak Rank',
		field: 'name',
		minWidth: 150,
		maxWidth: 150,
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
	},
	{
		headerName: 'Last Peak Rank Duration',
		cellRenderer: (params: CustomCellRendererProps) => {
			const rank = params.node.data as ExperienceRankingEntry;
			const differenceInMilliseconds = Math.abs(
				rank.end.getTime() - rank.start.getTime()
			);

			return formatDurationString(differenceInMilliseconds);
		},
		minWidth: 195,
		maxWidth: 195,
		resizable: false,
		sortable: false,
		context: {
			priority: 4,
		},
	},
	{
		headerName: 'Times At Peak Rank',
		field: 'num',
		minWidth: 165,
		maxWidth: 165,
		resizable: false,
		sortable: false,
		context: {
			priority: 3,
		},
	},
	{
		headerName: 'Total Peak Rank Duration',
		field: 'totalTime',
		minWidth: 200,
		maxWidth: 200,
		resizable: false,
		sortable: false,
		context: {
			priority: 2,
		},
		cellRenderer: (params: CustomCellRendererProps) => {
			const rankingEntry = params.node.data as ExperienceRankingEntry;

			return formatDurationString(rankingEntry.totalTime);
		},
	},
];

const getTopRankings = (
	rankings: ExperienceRanking[]
): ExperienceRankingEntry[] => {
	const rankToNumber = (s: string) => {
		return +s.replaceAll('#', '');
	};
	const topRankings: Record<number, ExperienceRankingEntry> = {};

	for (const rank of rankings) {
		const currentTop = topRankings[rank.y];
		if (
			currentTop &&
			rankToNumber(currentTop.name) < rankToNumber(rank.name)
		) {
			continue;
		}

		if (
			currentTop &&
			currentTop.name === rank.name &&
			rankToNumber(currentTop.name) > rankToNumber(rank.name)
		) {
			continue;
		}

		if (
			currentTop &&
			currentTop.name === rank.name &&
			rankToNumber(currentTop.name) === rankToNumber(rank.name)
		) {
			topRankings[rank.y] = {
				...(rank.end ? rank : currentTop),
				num: currentTop.num + 1,
				totalTime:
					rank.end.getTime() -
					rank.start.getTime() +
					currentTop.totalTime,
			};
			continue;
		}

		topRankings[rank.y] = {
			...rank,
			num: 1,
			totalTime: rank.end.getTime() - rank.start.getTime(),
		};
	}

	return Object.values(topRankings).map((rank) => {
		return { ...rank };
	});
};

interface ExperienceDetailsRankingTableProps {
	placeId: number;
	isHidden: boolean;
}

const ExperienceDetailsRankingTable = observer(
	function ExperienceDetailsRankingTable(
		props: ExperienceDetailsRankingTableProps
	) {
		const experienceRankings = experienceRankingStore.experienceRating(
			props.placeId
		);

		return (
			<>
				{!!experienceRankings?.length && (
					<>
						<div
							className={
								'w-full flex items-center justify-center text-2xl mt-2 mb-4'
							}
						>
							Ranking Milestones
						</div>

						<TGSTable
							columnDefs={ColumnDefs}
							rowData={getTopRankings(experienceRankings)}
							tableName={'experience-latest-top-rankings'}
							isHidden={props.isHidden}
							disablePagination
						/>
					</>
				)}
			</>
		);
	}
);

export default ExperienceDetailsRankingTable;
