import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import ClassString from '@utils/class-string.ts';
import Tooltip from '@components/core/tooltip/tooltip.tsx';

interface ExperienceDetailsInfoPanel {
	title: string;
	content: string | number | ReactNode;
	forceTruncate?: boolean;
	disableTruncate?: boolean;
	className?: string;
	tooltip?: string;
	fitContent?: boolean;
}

const ExperienceDetailsInfoPanel = observer(function ExperienceDetailsInfoPanel(
	props: ExperienceDetailsInfoPanel
) {
	const truncate =
		typeof props.content === 'string' ||
		typeof props.content === 'number' ||
		props.forceTruncate;

	return (
		<div
			className={ClassString({
				static: 'flex justify-start items-center bg-white dark:bg-gray-550 p-3 border border-gray-300 dark:border-gray-500 rounded-lg max-w-md min-w-40 w-fit h-20 shadow-lg',
				dynamic: {
					'w-fit': props.fitContent,
				},
				custom: props.className,
			})}
		>
			<div
				className={ClassString({
					static: 'w-full',
					dynamic: {
						truncate: truncate && !props.disableTruncate,
						'w-fit': props.fitContent,
					},
				})}
			>
				<div
					className={
						'text-lg font-semibold dark:font-normal text-blue-500 dark:text-blue-100'
					}
				>
					{props.title}
				</div>
				<div
					className={ClassString({
						static: 'text-2xl flex items-center min-w-48 justify-start w-full',
						dynamic: {
							'w-fit max-w-full': props.fitContent,
						},
					})}
				>
					{!!props.tooltip && (
						<Tooltip
							text={props.tooltip ?? ''}
							direction={'right'}
							widthClass={'w-fit'}
						>
							<div
								className={ClassString({
									static: 'w-full',
									dynamic: {
										truncate:
											truncate && !props.disableTruncate,
									},
								})}
							>
								{props.content}
							</div>
						</Tooltip>
					)}

					{!props.tooltip && (
						<div
							className={ClassString({
								static: 'w-full',
								dynamic: {
									truncate:
										truncate && !props.disableTruncate,
									'w-fit max-w-full': props.fitContent,
								},
							})}
						>
							{props.content}
						</div>
					)}
				</div>
			</div>
		</div>
	);
});

export default ExperienceDetailsInfoPanel;
