export const RankingCategories: Record<
	number,
	{ title: string; description: string }
> = {
	8: {
		title: 'Most Popular',
		description:
			'Experiences with the most daily users, sorted by users in-experience right now.',
	},
	9: {
		title: 'Top Earning',
		description:
			'Experiences with at least 5000 users daily, sorted by robux spent per user.',
	},
	10: {
		title: 'Up-and-Coming',
		description:
			'New experiences that users spent the most time in, that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	11: {
		title: 'Top Rated',
		description:
			'Experiences with at least 5000 users daily, that have both a high number and proportion of likes.',
	},
	12: {
		title: 'Trending in Roleplay',
		description:
			'Roleplay experiences with at least 100000 daily users that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	14: {
		title: 'Trending in Obby',
		description:
			'Obby experiences with at least 100000 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	16: {
		title: 'Trending in Simulator',
		description:
			'Simulator experiences with at least 100000 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	17: {
		title: 'Trending in Survival',
		description:
			'Survival experiences with at least 100000 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	18: {
		title: 'Top Trending',
		description:
			'Experiences that have the biggest relative increase in time spent over the last 2 weeks, sorted by users in-experience right now.',
	},
	19: {
		title: 'Fun with Friends',
		description:
			'Experiences with at least 5000 users daily, sorted by the proportion of time spent as groups of friends.',
	},
	20: {
		title: 'Trending in Action',
		description:
			'Action experiences with at least 100000 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	22: {
		title: 'Trending in Strategy',
		description:
			'Strategy experiences with at least 3500 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	24: {
		title: 'Trending in Sports',
		description:
			'Sports experiences with at least 10000 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	25: {
		title: 'Trending in Shooting',
		description:
			'Shooting experiences with at least 10000 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	26: {
		title: 'More When You Subscribe',
		description:
			'Experiences with at least 5000 users daily, sorted by time spent from users with subscriptions.',
	},
	27: {
		title: 'Only Ages 17+',
		description:
			'Experiences rated 17+ with at least 100 users daily, sorted by time spent.',
	},
	28: {
		title: 'Top Revisited',
		description:
			'Experiences with at least 5000 users daily, sorted by the proportion of users who come back after a week.',
	},
	29: {
		title: 'Top Paid Access',
		description: 'Paid access experiences sorted by daily revenue.',
	},
	30: {
		title: 'Trending Music Experiences',
		description:
			'Music experiences with at least 2500 users daily that have the biggest relative increase in time spent over the last 2 weeks.',
	},
	31: {
		title: 'Trending in Adventure',
		description:
			'Top 10% of experiences under this genre that have the largest relative increase in time spent over the last 2 weeks.',
	},
	32: {
		title: 'Trending in Entertainment',
		description:
			'Top 10% of experiences under this genre that have the largest relative increase in time spent over the last 2 weeks.',
	},
	33: {
		title: 'Trending in Party & Casual',
		description:
			'Top 10% of experiences under this genre that have the largest relative increase in time spent over the last 2 weeks.',
	},
	34: {
		title: 'Trending in Puzzle',
		description:
			'Top 10% of experiences under this genre that have the largest relative increase in time spent over the last 2 weeks.',
	},
	35: {
		title: 'Trending in RPG',
		description:
			'Top 10% of experiences under this genre that have the largest relative increase in time spent over the last 2 weeks.',
	},
	36: {
		title: 'Trending in Shopping',
		description:
			'Top 10% of experiences under this genre that have the largest relative increase in time spent over the last 2 weeks.',
	},
	37: {
		title: 'Trending in Social',
		description:
			'Top 10% of experiences under this genre that have the largest relative increase in time spent over the last 2 weeks.',
	},
};

export const GetRankingDescriptionByTitle = (title: string) => {
	for (const key in RankingCategories) {
		if (RankingCategories[key].title === title) {
			return RankingCategories[key].description;
		}
	}
	return 'No description';
};

export const GetRankingIndexByTitle = (title: string) => {
	for (const key in RankingCategories) {
		if (RankingCategories[key].title === title) {
			return +key;
		}
	}
	return 0;
};
