enum BrandColors {
	black = '#0c0c0c',
	white = '#ffffff',

	gray100 = '#f6f8f7',
	gray200 = '#e1e1e1',
	gray300 = '#c2c2c2',
	gray400 = '#a4a4a4',
	gray500 = '#676767',
	gray525 = '#575757',
	gray550 = '#464646',
	gray575 = '#363636',
	gray600 = '#252525',

	yellow100 = '#ffffce',
	yellow200 = '#feff83',
	yellow300 = '#feff3a',
	yellow400 = '#fff731',
	yellow500 = '#ffe600',
	yellow600 = '#ead300',

	blue100 = '#b8e5ff',
	blue200 = '#96d9ff',
	blue300 = '#73ccff',
	blue400 = '#6d74fd',
	blue500 = '#3c45fe',
	blue525 = '#2E36E5',
	blue550 = '#1F27CD',
	blue575 = '#1118B4',
	blue600 = '#02099b',

	green100 = '#edfec5',
	green200 = '#dafe8d',
	green300 = '#c8fd54',
	green400 = '#b5fc1b',
	green500 = '#8ecf06',
	green600 = '#476700',

	red100 = '#fedee4',
	red200 = '#ffd4dc',
	red300 = '#fea9b7',
	red400 = '#fe7d95',
	red500 = '#ff2e54',
	red525 = '#E42840',
	red550 = '#C9222D',
	red575 = '#AD1B19',
	red600 = '#921505',

	pink100 = '#ffe9fc',
	pink200 = '#ffdcfb',
	pink300 = '#ffc2f8',
	pink400 = '#ff86f0',
	pink500 = '#ff04e2',
	pink600 = '#ae009a',
}

export default BrandColors;
