import { observer } from 'mobx-react-lite';
import { Experience, SlimExperience } from '@/schemas/experience-schema.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import BrandColors from '@styles/_brand_colors.ts';
import { RowStyle } from 'ag-grid-community';
import themeStore from '@store/theme-store.ts';

interface RankingsExperiencesListProps {
	experiences: (SlimExperience | undefined)[];
	referencedExperience?: number;
}

const RankingsExperiencesList = observer(function RankingsExperiencesList(
	props: RankingsExperiencesListProps
) {
	const highlightColor = themeStore.lightTheme
		? BrandColors.blue100
		: BrandColors.blue400;

	const ColumnDef: CustomColDef<SlimExperience | undefined>[] = [
		{
			minWidth: 55,
			maxWidth: 55,
			cellRenderer: (params: CustomCellRendererProps) => {
				const placement = (params.node.rowIndex ?? 0) + 1;
				return (
					<div
						className={
							'text-2xl font-bold flex justify-center items-center '
						}
					>
						{placement}
					</div>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
			resizable: false,
			sortable: false,
		},
		{
			minWidth: 55,
			maxWidth: 55,
			cellRenderer: (params: CustomCellRendererProps) => {
				const experience = params.node.data as Experience;

				return (
					<div
						className={
							'w-full h-full flex justify-center items-center'
						}
					>
						{!!experience && (
							<PlaceThumbnail
								place={experience}
								size={PlaceThumbnailSize.VERY_SMALL}
								className={'rounded-md'}
							/>
						)}
					</div>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingRight: '0',
			},
			resizable: false,
			sortable: false,
			context: {
				priority: 2,
			},
		},
		{
			headerName: 'Experience',
			field: 'name',
			cellRenderer: (params: CustomCellRendererProps) => {
				const experience = params.node.data as SlimExperience;
				return (
					<>
						{!!experience && (
							<NavigationButton
								path={`/experiences/${experience.place_id}`}
							>
								{experience.name}
							</NavigationButton>
						)}

						{!experience && <div>-</div>}
					</>
				);
			},
			resizable: false,
			sortable: false,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '25px',
				paddingRight: '0',
			},
			headerClass: '!pl-7',
			flex: 1,
		},
	];

	return (
		<TGSTable
			columnDefs={ColumnDef}
			rowData={props.experiences}
			tableName={'ranking-experiences'}
			getRowStyle={(params) => {
				const experience = params.data;

				if (
					!experience ||
					experience.place_id !== props.referencedExperience
				) {
					return {};
				}

				return {
					backgroundColor: highlightColor,
				} as RowStyle;
			}}
			disablePagination
		/>
	);
});

export default RankingsExperiencesList;
