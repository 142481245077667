import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import experienceStore from '@store/experience-store.ts';
import GenreTable from '@views/genres/view-components/genre-table.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
const Genres = observer(function Genres() {
	const genres = experienceStore.getGenres();

	const tableData = Object.entries(genres).map(([genre, experiences]) => {
		return {
			name: genre,
			experiences,
			mainGenre: genre,
		};
	});

	return (
		<View
			viewInfo={{
				title: 'Genres',
				icon: IconEnum.GENRE,
				iconSize: '30px',
			}}
			loading={!experienceStore.slimExperiences.length}
			hideHeader
		>
			<ViewPanel className={'max-w-screen-lg'}>
				<GenreTable genres={tableData} genreColumnHeader={'Genres'} />
			</ViewPanel>
		</View>
	);
});

export default Genres;
