import View from '@components/view/view.tsx';
import experienceStore from '@store/experience-store.ts';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import PlaceGenreDistribution from '@components/data/place-genre-distribution/place-genre-distribution.tsx';
import PlaceLikeDistribution from '@components/data/place-like-distribution/place-like-distribution.tsx';
import TopCreatorsList from '@views/overview/view-components/top-creators-list.tsx';
import TopCreatorsListTypeEnum from '@views/overview/view-components/top-creators-list-enum.ts';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import PlatformCCUChart from '@views/overview/view-components/platform-ccu-chart.tsx';
import calculateAverage from '@utils/calculate-average.ts';
import NavigateToExperience from '@components/navigation/navigate-to-experience/navigate-to-experience.tsx';
import ExperienceTopList from '@components/tables/experience-top-list.tsx';
import dailyPickStore from '@store/daily-pick-store.ts';
import PlaceBanner from '@components/data/place-banner/place-banner.tsx';
import { PlaceBannerSize } from '@components/data/place-banner/place-banner-constants.ts';
import { Link } from 'react-router-dom';
import Tooltip from '@components/core/tooltip/tooltip.tsx';

const Overview = observer(function Overview() {
	const [loading, setLoading] = useState(true);
	const initialized = experienceStore.initialized;

	const places = experienceStore.slimExperiences;
	const totalPlayersOnline = places.reduce(
		(sum, experience) => sum + (experience.players_online || 0),
		0
	);

	const dailyPicks = dailyPickStore.currentDailyPick
		? dailyPickStore.currentDailyPick.places
				.map((place) => {
					return experienceStore.getFullExperienceById(
						place.place_id
					);
				})
				.filter((exp) => !!exp)
		: [];

	const top5Places = [...places]
		.sort((a, b) => b.players_online - a.players_online)
		.slice(0, 5);

	useEffect(() => {
		if (initialized) {
			setLoading(false);
		}
	}, [initialized]);

	const dailyCCU = [...platformStatsStore.platformCCU].sort(
		(a, b) => a.ccu - b.ccu
	);
	const highestCCU = dailyCCU.length
		? dailyCCU[dailyCCU.length - 1]
		: { ccu: 0, time: 0 };

	const lastMonthAverageCCU = () => {
		const now = new Date();
		const firstDayOfCurrentMonth = new Date(
			now.getFullYear(),
			now.getMonth(),
			1
		);
		const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
		lastDayOfPreviousMonth.setDate(lastDayOfPreviousMonth.getDate() - 1);
		lastDayOfPreviousMonth.setHours(23, 59, 59, 999);
		const firstDayOfPreviousMonth = new Date(
			lastDayOfPreviousMonth.getFullYear(),
			lastDayOfPreviousMonth.getMonth(),
			1
		);

		const ccu = +calculateAverage(
			dailyCCU
				.filter((ccu) => {
					const time = ccu.time.getTime();
					return (
						time > firstDayOfPreviousMonth.getTime() &&
						time < lastDayOfPreviousMonth.getTime()
					);
				})
				.map((ccu) => ccu.ccu)
		);
		return ccu.toFixed(0);
	};

	return (
		<View
			viewInfo={{
				title: 'Overview',
				icon: IconEnum.HOME,
				iconSize: '30px',
			}}
			hideHeader
		>
			<div className={'w-full px-2'}>
				<NavigateToExperience />
			</div>

			<ViewPanel loading={platformStatsStore.loading}>
				{!!platformStatsStore.platformCCUChartData.length && (
					<>
						<PlatformCCUChart />

						<div
							className={
								'w-full flex justify-around flex-col xl:flex-row'
							}
						>
							<QuickData
								data={new Intl.NumberFormat(
									navigator.language
								).format(+lastMonthAverageCCU())}
								title={'Average Daily CCU Last Month'}
								className={'mt-0 mb-6 xl:mb-0'}
							/>

							<QuickData
								data={`~${new Intl.NumberFormat(
									navigator.language
								).format(totalPlayersOnline)}`}
								title={'Players Online'}
								className={'mt-0 mb-6 xl:mb-0'}
							/>

							<div className={'flex flex-col items-center'}>
								<QuickData
									data={new Intl.NumberFormat(
										navigator.language
									).format(highestCCU.ccu)}
									title={'Highest Daily CCU'}
									className={'mt-0 mb-0'}
								/>
								<div className={'text-sm'}>
									{new Date(
										highestCCU.time
									).toLocaleDateString()}
								</div>
							</div>
						</div>
					</>
				)}

				{!platformStatsStore.platformCCUChartData.length && (
					<div className={'w-full flex justify-center'}>
						No Roblox CCU data
					</div>
				)}
			</ViewPanel>

			{!!dailyPicks.length && (
				<ViewPanel options={{ container: true }}>
					<div className={'text-2xl mb-2'}>
						<Tooltip
							text={
								'A curated selection of daily highlights by Roblox staff'
							}
							direction={'bottom'}
							className={'justify-start cursor-default'}
						>
							{"Roblox's Daily Picks"}
						</Tooltip>
					</div>

					<div
						className={
							'grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-center items-start gap-8'
						}
					>
						{dailyPicks.map((exp) => {
							return (
								<div
									className={
										'max-w-full flex flex-col justify-center items-center'
									}
									key={`daily-pick-${exp?.place_id}`}
								>
									<Link
										to={`/experiences/${exp?.place_id}`}
										className={
											'flex flex-col justify-center items-center gap-2 hover:bg-blue-500 hover:text-white w-fit p-2 rounded-lg'
										}
										draggable={false}
									>
										<PlaceBanner
											place={exp}
											size={PlaceBannerSize.MEDIUM}
											className={
												'rounded-md outline outline-1 outline-gray-300 dark:outline-gray-500'
											}
										/>

										<div
											className={
												'w-full flex justify-center items-center text-center'
											}
										>
											{exp?.name}
										</div>
									</Link>
								</div>
							);
						})}
					</div>
				</ViewPanel>
			)}

			<LightdashDashboards
				dashboards={[
					{ type: DashboardType.TAGS_OVERVIEW },
					{ type: DashboardType.TOP_100_WEEKLY },
				]}
			/>

			<ViewPanel loading={loading} className={'overview-top-5-panel'}>
				<div className={'mb-4'}>
					<PlaceStats
						places={top5Places}
						statType={PlaceStatType.CCUS}
						title={'Concurrent Users (Current Top 5 Experiences)'}
						options={{
							showLegend: true,
							defaultGranularity: PlaceStatGranularity.WEEK,
							selectedRange: 7,
						}}
					/>
				</div>

				<ExperienceTopList experiences={top5Places} />
			</ViewPanel>

			<ViewPanel loading={loading}>
				<PlaceGenreDistribution
					title={'Platform Genre Distribution'}
					height={800}
				/>
			</ViewPanel>

			<ViewPanel loading={loading}>
				<PlaceLikeDistribution places={places} />
			</ViewPanel>

			<ViewPanel
				title={'Top Creators (Total Visits)'}
				loading={loading}
				options={{ size: ViewPanelSizeEnum.HALF }}
			>
				<TopCreatorsList
					places={places}
					listBy={TopCreatorsListTypeEnum.BY_TOTAL_VISITS}
				/>
			</ViewPanel>

			<ViewPanel
				title={'Top Creators (Total Players Online)'}
				loading={loading}
				options={{ size: ViewPanelSizeEnum.HALF }}
			>
				<TopCreatorsList
					places={places}
					listBy={TopCreatorsListTypeEnum.BY_CURRENT_PLAYERS}
				/>
			</ViewPanel>
		</View>
	);
});

export default Overview;
