import {
	createBrowserRouter,
	Outlet,
	redirect,
	RouteObject,
} from 'react-router-dom';
import authStore from '@store/auth-store.ts';
import App from '@/app.tsx';
import Overview from '@views/overview/overview.tsx';
import Places from '@views/places/places.tsx';
import ExperienceDetails from '@views/experience-details/experience-details.tsx';
import ExperienceTags from '@views/experience-tags/experience-tags.tsx';
import ExperienceTagDetails from '@views/experience-tag-details/experience-tag-details.tsx';
import RobuxConverter from '@views/robux-converter/robux-converter.tsx';
import DeepLink from '@views/deep-link/deep-link.tsx';
import { ExperienceTagDetailsLoader } from '@views/experience-tag-details/experience-tag-details-loader.ts';
import Error from '@views/error/error.tsx';
import { ExperienceTagsLoader } from '@views/experience-tags/experience-tags-loader.ts';
import { ExperienceDetailsLoader } from '@views/experience-details/experience-details-loader.ts';
import LoadingView from '@views/loading-view/loading-view.tsx';
import { OverviewLoader } from '@views/overview/overview-loader.ts';
import experienceStore from '@store/experience-store.ts';
import validationService from '@services/validation-service.ts';
import ExperienceComparison from '@views/experience-comparison/experience-comparison.tsx';
import Users from '@views/users/users.tsx';
import userStore from '@store/user-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import AuthCheck from '@views/auth-check/auth-check.tsx';
import CodeLibrary from '@views/code-library/code-library.tsx';
import EventCredentials from '@views/event-credentials/event-credentials.tsx';
import { EventCredentialsLoader } from '@views/event-credentials/event-credentials-loader.ts';
import ExperienceTagComparison from '@views/experience-tag-comparison/experience-tag-comparison.tsx';
import TagComparison from '@views/tag-comparison/tag-comparison.tsx';
import Creators from '@views/creators/creators.tsx';
import CreatorDetails from '@views/creator-details/creator-details.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import { CreatorDetailsLoader } from '@views/creator-details/creator-details-loader.ts';
import creatorStore from '@store/creator-store.ts';
import viewStore from '@store/view-store.ts';
import DailyPicks from '@views/daily-picks/daily-picks.tsx';
import Rankings from '@views/rankings/rankings.tsx';
import { RankingsLoader } from '@views/rankings/rankings-loader.ts';
import { RankingCategories } from '@/enums/ranking-categories.ts';
import Genres from '@views/genres/genres.tsx';
import Genre from '@views/genres/genre.tsx';
import SubGenre from '@views/genres/sub-genre.tsx';
import SignIn from '@views/sign-in/sign-in.tsx';
import SignUp from '@views/sign-up/sign-up.tsx';

// Deprecated routes, still supported for potential bookmarks
const oldPaths: RouteObject[] = [
	{
		path: 'places',
		loader: () => {
			return redirect('/experiences');
		},
		children: [
			{
				path: ':placeId',
				loader: (args) => {
					return redirect(`/experiences/${args.params.placeId}`);
				},
			},
		],
	},
	{
		path: 'place-tags',
		loader: () => {
			return redirect('/tags');
		},
		children: [
			{
				path: ':placeTagId',
				loader: (args) => {
					return redirect(`/tags/${args.params.placeTagId}`);
				},
			},
		],
	},
	{
		path: 'experiences/compare',
		loader: () => {
			return redirect(`/compare/experiences${window.location.hash}`);
		},
	},
	{
		path: 'login',
		loader: () => {
			return redirect('/sign-in');
		},
	},
];

const router = createBrowserRouter([
	{
		path: '',
		element: <App />,
		children: [
			{
				path: '/',
				loader: async () => {
					if (await authStore.isAuthenticated()) {
						return redirect('/overview');
					} else {
						return redirect('/sign-in');
					}
				},
			},
			{
				path: '*',
				loader: () => {
					return redirect('/');
				},
			},
			{
				path: 'sign-in',
				loader: async () => {
					if (await authStore.isAuthenticated()) {
						return redirect('/overview');
					}

					return null;
				},
				element: <SignIn />,
			},
			{
				path: 'sign-up',
				loader: async () => {
					if (await authStore.isAuthenticated()) {
						return redirect('/overview');
					}

					return null;
				},
				element: <SignUp />,
			},
			{
				loader: async () => {
					// Is this really updated before the new view component is mounted?
					viewStore.loadingViewData = false;

					if (!(await authStore.isAuthenticated())) {
						return null;
					}

					if (!experienceStore.initialized) {
						void experienceStore.loadSlimExperiences();
					}

					if (!placeTagStore.initialized) {
						void placeTagStore.loadPlaceTags();
					}

					if (!creatorStore.initialized) {
						void creatorStore.loadCreators();
					}

					return null;
				},
				shouldRevalidate: () => true,
				element: (
					<AuthCheck>
						<Outlet />
					</AuthCheck>
				),
				children: [
					{
						path: 'loading',
						element: <LoadingView />,
					},
					{
						path: 'overview',
						element: <Overview />,
						loader: OverviewLoader,
					},
					{
						path: 'experiences',
						children: [
							{
								index: true,
								element: <Places />,
							},
							{
								path: ':experienceId',
								element: <ExperienceDetails />,
								loader: async ({ params }) => {
									const experienceId = params.experienceId;
									if (
										!experienceId ||
										!validationService.databaseId(
											experienceId
										)
									) {
										throw new Response(null, {
											status: 404,
										});
									}

									return await ExperienceDetailsLoader(
										experienceId
									);
								},
								errorElement: <Error />,
							},
						],
					},
					{
						path: 'tags',
						element: <LoadingView />,
						children: [
							{
								index: true,
								element: <ExperienceTags />,
								loader: ExperienceTagsLoader,
							},
							{
								path: ':placeTagId',
								element: <ExperienceTagDetails />,
								loader: async ({ params }) => {
									const placeTagId = params.placeTagId;
									if (
										!placeTagId ||
										!validationService.databaseId(
											placeTagId
										)
									) {
										throw new Response(null, {
											status: 404,
										});
									}

									return await ExperienceTagDetailsLoader(
										placeTagId
									);
								},
								errorElement: <Error />,
							},
							{
								path: 'compare',
								element: <ExperienceTagComparison />,
								errorElement: <Error />,
								loader: ExperienceTagsLoader,
							},
						],
					},
					{
						path: 'creators',
						element: <LoadingView />,
						children: [
							{
								index: true,
								element: <Creators />,
							},
							{
								path: ':creatorId',
								element: <CreatorDetails />,
								loader: async ({ params }) => {
									const creatorId = params.creatorId;

									if (
										!creatorId ||
										!validationService.databaseId(creatorId)
									) {
										throw new Response(null, {
											status: 404,
										});
									}

									return await CreatorDetailsLoader(
										creatorId
									);
								},
								errorElement: <Error />,
							},
						],
					},
					{
						path: 'daily-picks',
						element: <DailyPicks />,
					},
					{
						path: 'compare/experiences',
						element: <ExperienceComparison />,
						errorElement: <Error />,
					},
					{
						path: 'compare/tags',
						element: <TagComparison />,
						errorElement: <Error />,
						loader: ExperienceTagsLoader,
					},
					{
						path: 'compare/experience-tag',
						element: <ExperienceTagComparison />,
						errorElement: <Error />,
						loader: ExperienceTagsLoader,
					},
					{
						path: 'robux',
						element: <RobuxConverter />,
					},
					{
						path: 'deep-link',
						element: <DeepLink />,
					},
					{
						path: 'event-credentials',
						element: <EventCredentials />,
						loader: EventCredentialsLoader,
					},
					{
						path: 'users',
						element: <Users />,
						loader: async () => {
							if (
								(await authStore.isAuthenticated()) &&
								!authStore.havePermission(
									UserPermission.LIST_USERS
								)
							) {
								return redirect('/');
							}

							await userStore.loadUsers();
							return null;
						},
					},
					{
						path: 'code-library',
						element: <CodeLibrary />,
					},
					{
						path: 'rankings/:rankingIndex',
						element: <Rankings />,
						loader: async ({ params }) => {
							const rankingIndex = params.rankingIndex;

							if (
								!rankingIndex ||
								!RankingCategories[+rankingIndex]
							) {
								throw new Response(null, {
									status: 404,
								});
							}

							return await RankingsLoader(+rankingIndex);
						},
						errorElement: <Error />,
					},
					{
						path: 'genres',
						children: [
							{
								index: true,
								element: <Genres />,
							},
							{
								path: ':genre',
								children: [
									{
										index: true,
										element: <Genre />,
										loader: ({ params }) => {
											const genre = params.genre;
											if (!genre) {
												{
													throw new Response(null, {
														status: 404,
													});
												}
											}

											return { genre };
										},
									},
									{
										path: ':subGenre',
										element: <SubGenre />,
										loader: ({ params }) => {
											const genre = params.genre;
											const subGenre = params.subGenre;
											if (!genre || !subGenre) {
												{
													throw new Response(null, {
														status: 404,
													});
												}
											}

											return { genre, subGenre };
										},
									},
								],
							},
						],
					},
					...oldPaths,
				],
			},
		],
	},
]);

export default router;
