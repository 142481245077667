export enum HashParamKey {
	Experience = 'exp',
	Tag = 'tag',
	Search = 'search',
	Anchor = 'anchor',
	GraphMin = 'graphMin',
	GraphMax = 'graphMax',
	Granularity = 'granularity',
	Tab = 'tab',
	StartDate = 'startDate',
	EndDate = 'endDate',
	time = 'time',
}

type HashParamKeyType = `${HashParamKey}-${number}` | `${HashParamKey}`;

export type HashParamMap = Partial<Record<HashParamKeyType, string>>;

const dynamicPattern = new RegExp(
	`^(${Object.values(HashParamKey).join('|')})-\\d+$`
);

export const stringToHashParamKey = (s: string) => {
	if (
		!Object.values(HashParamKey).includes(s as HashParamKey) &&
		!dynamicPattern.test(s)
	) {
		throw new Error(`"${s}" not a valid HashParamKey`);
	}

	return s as HashParamKey;
};
