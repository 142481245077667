import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import authStore from '@store/auth-store.ts';
import InputText from '@components/core/input-text/input-text.tsx';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { Link } from 'react-router-dom';
import View from '@components/view/view.tsx';
import { INPUT_TEXT_TYPES } from '@components/core/input-text/input-text-enum.ts';
import Form from '@components/core/form/form.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import LogoBlack from '@assets/logo_black.svg';
import ClassString from '@utils/class-string.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';
import validationService from '@services/validation-service.ts';

const SignUp = observer(function SignUp() {
	const screenBreakpoint = useScreenBreakpoint();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [showIncomplete, setShowIncomplete] = useState(false);
	const [loading, setLoading] = useState(false);

	const [accountCreated, setAccountCreated] = useState(false);

	const passwordsNotMatching =
		!!password.length &&
		!!confirmPassword.length &&
		password !== confirmPassword;

	const incomplete =
		!firstName.length ||
		!lastName.length ||
		!validationService.email(email) ||
		password.length < 8 ||
		confirmPassword.length < 8 ||
		passwordsNotMatching;

	const handleSignUp = async () => {
		if (incomplete) {
			setShowIncomplete(true);
			return;
		}

		try {
			setLoading(true);
			const success = await authStore.register(
				firstName,
				lastName,
				email,
				password
			);
			setLoading(false);

			if (success) {
				setAccountCreated(true);
			}
		} catch (_) {
			setLoading(false);
		}
	};

	return (
		<View
			viewInfo={{ title: 'Sign In' }}
			className={'bg-white sm:bg-transparent'}
			hideHeader
		>
			<div className={'absolute top-3 left-3 max-w-24'}>
				<img src={LogoBlack} alt={'logo-full'} draggable="false" />
			</div>

			<ViewPanel
				className={'max-w-md'}
				options={{ container: screenBreakpoint <= ScreenBreakpoint.SM }}
			>
				<div
					className={ClassString({
						static: 'flex flex-col justify-center items-center p-4',
						dynamic: {
							'opacity-0': loading,
							hidden: accountCreated,
						},
					})}
				>
					<div
						className={
							'text-4xl font-bold tracking-wider pl-2 select-none mb-6'
						}
					>
						<span>←</span>
						<span className={'px-1'}>TOOLS</span>
						<span>→</span>
					</div>

					<div className={'w-full text-2xl mb-1 font-semibold'}>
						Sign Up
					</div>
					<div className={'w-full mb-6'}>
						Sign up to access additional features and historical
						data
					</div>

					<Form
						className={
							'flex flex-col justify-center items-center w-full'
						}
						onSubmit={() => {
							void handleSignUp();
						}}
					>
						<div className={'grid grid-cols-2 gap-4'}>
							<InputText
								label={'First Name'}
								value={firstName}
								onInput={(event) =>
									setFirstName(event.target.value)
								}
								type={INPUT_TEXT_TYPES.TEXT}
								options={{ width: '100%', large: true }}
								inputClassName={ClassString({
									dynamic: {
										'border-red-525':
											showIncomplete && !firstName.length,
									},
								})}
							/>

							<InputText
								label={'Last Name'}
								value={lastName}
								onInput={(event) =>
									setLastName(event.target.value)
								}
								type={INPUT_TEXT_TYPES.TEXT}
								options={{ width: '100%', large: true }}
								inputClassName={ClassString({
									dynamic: {
										'border-red-525':
											showIncomplete && !lastName.length,
									},
								})}
							/>
						</div>

						<InputText
							label={'Email'}
							value={email}
							onInput={(event) => setEmail(event.target.value)}
							type={INPUT_TEXT_TYPES.EMAIL}
							options={{ width: '100%', large: true }}
							inputClassName={ClassString({
								dynamic: {
									'border-red-525':
										showIncomplete &&
										!validationService.email(email),
								},
							})}
						/>

						<div className={'relative w-full'}>
							<div
								className={ClassString({
									static: 'absolute -top-2 right-4 text-sm text-red-525 hidden',
									dynamic: {
										block:
											showIncomplete &&
											password.length < 8,
									},
								})}
							>
								{'Password needs to be longer'}
							</div>

							<InputText
								label={'Password'}
								value={password}
								onInput={(event) =>
									setPassword(event.target.value)
								}
								type={INPUT_TEXT_TYPES.PASSWORD}
								options={{ width: '100%', large: true }}
								inputClassName={ClassString({
									dynamic: {
										'border-red-525':
											passwordsNotMatching ||
											(showIncomplete &&
												password.length < 8),
									},
								})}
							/>
						</div>

						<div className={'relative w-full'}>
							<div
								className={ClassString({
									static: 'absolute -top-2 right-4 text-sm text-red-525 hidden',
									dynamic: {
										block: passwordsNotMatching,
									},
								})}
							>
								{'Password does not match'}
							</div>

							<InputText
								label={'Confirm Password'}
								value={confirmPassword}
								onInput={(event) =>
									setConfirmPassword(event.target.value)
								}
								type={INPUT_TEXT_TYPES.PASSWORD}
								options={{ width: '100%', large: true }}
								inputClassName={ClassString({
									dynamic: {
										'border-red-525':
											passwordsNotMatching ||
											(showIncomplete &&
												confirmPassword.length < 8),
									},
								})}
							/>
						</div>

						<Button
							title={'Sign Up'}
							buttonStyle={BUTTON_STYLE.PRIMARY}
							className={'mt-2 w-full py-6 rounded-full max-w-80'}
						/>
					</Form>
				</div>

				<div
					className={ClassString({
						static: 'flex flex-col justify-center items-center p-4',
						dynamic: {
							'opacity-0': loading,
							hidden: !accountCreated,
						},
					})}
				>
					<div className={'w-full mb-6 text-center'}>
						Account successfully created
					</div>

					<Link
						to={'/sign-in'}
						className={
							'mt-2 w-full py-6 rounded-full max-w-80 button relative flex items-center justify-center h-button transition-all duration-75 px-5 whitespace-nowrap border  text-white shadow-md bg-gray-600  border-gray-500  focus-visible:outline-blue-500 focus-visible:outline-double focus-visible:outline-4 focus-visible:bg-blue-500 focus-visible:text-white focus-visible:decoration-white focus-visible:border-blue-500 hover:bg-blue-500 hover:text-white hover:decoration-white hover:border-blue-500'
						}
					>
						Sign In
					</Link>
				</div>

				<div
					className={ClassString({
						static: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none',
						dynamic: { 'opacity-0': !loading },
					})}
				>
					<LoadingIndicator />
				</div>
			</ViewPanel>

			<div
				className={
					'max-w-md absolute sm:static bottom-3 left-1/2 transform sm:transform-none -translate-x-1/2'
				}
			>
				<span className={'mr-2'}>{'Already got an account?'}</span>
				<Link to={'/sign-in'} className={'text-blue-500'}>
					Sign In
				</Link>
			</div>
		</View>
	);
});

export default SignUp;
