import { EventCredential } from '@/schemas/event-credential-schema.ts';
import { observer } from 'mobx-react-lite';
import experienceStore from '@store/experience-store.ts';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import useOnClickOutside from '@hooks/use-on-click-outside.tsx';
import OptionsMenu from '@components/core/options-menu/options-menu.tsx';
import ModalBase from '@components/modal/modal-base.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import eventCredentialStore from '@store/event-credential-store.ts';
import ClassString from '@utils/class-string.ts';
import EditableText from '@components/core/editable-text/editable-text.tsx';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import CopyService from '@services/copy-service.ts';
import modalStore from '@store/modal-store.ts';
import ExpansionPanel from '@components/core/expansion-panel/expansion-panel.tsx';
import EventCredentialEventStatsGraph from '@views/event-credentials/view-components/event-credential-event-stats-graph.tsx';
import eventStatsStore from '@store/event-stats-store.ts';

interface EventCredentialInfoProps {
	credential: EventCredential;
	secretVisible: boolean;
	setSecretVisible: (visible: boolean) => void;
}

const numberFormatter = new Intl.NumberFormat(navigator.language);

const EventCredentialInfo = observer(function EventCredentialInfo(
	props: EventCredentialInfoProps
) {
	const { ref } = useOnClickOutside<HTMLInputElement>(() => {
		if (props.secretVisible) {
			setInternalSecretVisible(false);
		}
	});

	const [internalSecretVisible, setInternalSecretVisible] = useState(false);
	const updateVisibility = useDebounce(internalSecretVisible, 1); // Use 1 ms to avoid setting twice in same render

	useEffect(() => {
		props.setSecretVisible(internalSecretVisible);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateVisibility]);

	const place = experienceStore.getSlimExperienceById(
		props.credential.place_id
	);

	const updateName = (name: string) => {
		void eventCredentialStore.updateName(props.credential, name);
	};

	const title = place
		? `${place.canonical_name} - Experience ID: ${props.credential.place_id}`
		: `Experience ID: ${props.credential.place_id}`;

	const eventStats = eventStatsStore.getEventStatById(
		props.credential.place_id
	);
	const totalEvents = eventStats.reduce(
		(acc, curr) => acc + curr.num_events,
		0
	);

	return (
		<div className={'w-full h-full flex flex-col items-center gap-4'}>
			<div className={'w-full flex items-center gap-4'}>
				<div className={'cursor-help'} title={title}>
					<PlaceThumbnail
						place={place}
						size={PlaceThumbnailSize.SMALL}
						className={'min-w-24 max-w-24 w-24 rounded-md'}
					/>
				</div>

				<div
					className={
						'flex flex-col justify-around w-full h-full gap-2'
					}
				>
					<div className={'flex items-center font-medium gap-4'}>
						<div
							className={ClassString({
								static: '',
								dynamic: {
									'text-gray-400 line-through':
										props.credential.disabled,
								},
							})}
						>
							<EditableText
								text={props.credential.comment}
								onTextChange={updateName}
								noLeftPadding
							/>
						</div>
						{props.credential.disabled && (
							<div
								className={
									'text-red-500 dark:text-red-400 flex items-center gap-1'
								}
							>
								<Icon icon={IconEnum.DISABLED} />
								<div>Disabled</div>
							</div>
						)}
					</div>
					<div
						className={
							'grid grid-cols-1 xl:grid-cols-2 w-full gap-2'
						}
					>
						<div>
							<div
								className={
									'font-semibold dark:font-normal text-blue-500 dark:text-blue-100 h-6'
								}
							>
								Game Key
							</div>

							<div
								className={
									'text-sm sm:text-base cursor-pointer'
								}
								onClick={() =>
									CopyService.gameKey(props.credential)
								}
							>
								{props.credential.game_key}
							</div>
						</div>

						<div>
							<div className={'flex items-center gap-2 h-6'}>
								<div
									className={
										'font-semibold dark:font-normal text-blue-500 dark:text-blue-100'
									}
								>
									Secret Key
								</div>
								<Icon
									icon={
										props.secretVisible
											? IconEnum.VISIBILITY_OFF
											: IconEnum.VISIBILITY
									}
									size={'2em'}
									onClick={() => {
										setInternalSecretVisible(
											!props.secretVisible
										);
									}}
									className={
										'cursor-pointer hover:bg-blue-500 hover:text-white rounded-full p-1'
									}
								/>
							</div>
							<div className={'flex items-center gap-2'}>
								<input
									type={
										props.secretVisible
											? 'text'
											: 'password'
									}
									value={props.credential.secret_key}
									readOnly
									className={
										'w-full bg-transparent text-sm sm:text-base cursor-pointer outline-none'
									}
									ref={ref}
									onClick={(event) => {
										event.preventDefault();
										CopyService.secretKey(props.credential);
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div>
					<OptionsMenu
						buttons={[
							{
								title: props.credential.disabled
									? 'Enable'
									: 'Disable',
								onClick: () => {
									modalStore.open(
										<ModalBase
											title={`${props.credential.disabled ? 'Enable' : 'Disable'} Event Credential`}
											content={{
												description: `Are you sure you want to ${props.credential.disabled ? 'enable' : 'disable'} the event credential for "${props.credential.comment}"? While ${props.credential.disabled ? 'enabled' : 'disabled'}, the game will ${props.credential.disabled ? '' : 'no longer'} be able to send events.`,
											}}
											onConfirm={async () => {
												await eventCredentialStore.updateDisabled(
													props.credential,
													!props.credential.disabled
												);
											}}
											options={{
												confirmButtonTitle: props
													.credential.disabled
													? 'Enable'
													: 'Disable',
												confirmButtonStyle: props
													.credential.disabled
													? BUTTON_STYLE.PRIMARY
													: BUTTON_STYLE.WARNING,
											}}
										/>
									);
								},
							},
							{
								title: 'Delete',
								onClick: () => {
									modalStore.open(
										<ModalBase
											title={`Delete Event Credential`}
											content={{
												description: `Are you sure you want to permanently delete the event credential for "${props.credential.comment}"? The Game will no longer be able to send events, this action cannot be reversed.`,
											}}
											onConfirm={async () => {
												await eventCredentialStore.deleteEventCredential(
													props.credential
												);
											}}
											options={{
												confirmButtonTitle:
													'Permanently Delete',
												confirmButtonStyle:
													BUTTON_STYLE.WARNING,
											}}
										/>
									);
								},
							},
						]}
					/>
				</div>
			</div>

			<ExpansionPanel
				title={`Events (${numberFormatter.format(totalEvents)})`}
				className={'w-full rounded-md'}
				colorOnHover
			>
				<EventCredentialEventStatsGraph
					experienceId={props.credential.place_id}
					title={`Daily Events`}
				/>
			</ExpansionPanel>
		</div>
	);
});

export default EventCredentialInfo;
