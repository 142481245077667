import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	PlaceStatsSchema,
	PlaceStatsSeries,
	PlaceTagStatsSchema,
} from '@/schemas/place-stats-schema.ts';
import experienceStore from '@store/experience-store.ts';
import { Experience, FullExperience } from '@/schemas/experience-schema.ts';
import previousEvenTenMinuteDate from '@utils/previous-even-ten-minutes-date.ts';
import { PlaceTag, PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';
import { Creator } from '@/schemas/creator-schema.ts';
import getNearestPreviousDate from '@utils/get-nearest-previous-date.ts';

export enum PlaceStatType {
	CCUS = 'ccus',
	RATING = 'rating',
	VISITS = 'visits',
	LIKES = 'likes',
	PLAYTIME = 'playtime',
}

export enum PlaceStatGranularity {
	HOUR = 'hour',
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
}

export const isValidGranularity = (granularity: string): boolean => {
	return Object.values(PlaceStatGranularity).includes(
		granularity as PlaceStatGranularity
	);
};

export enum CCUDataSegment {
	ALL = 'all',
	ACTIVE = 'active',
	TOP_FIVE = 'top_five',
}

const concurrentUsersApi = `${import.meta.env.VITE_SERVER_URL}/api/stats`;

class PlaceStatsStore {
	private concurrentUserStats: Record<
		number,
		Record<PlaceStatGranularity, number[][]>
	> = {};
	private ratingStats: Record<
		number,
		Record<PlaceStatGranularity, number[][]>
	> = {};
	private visitsStats: Record<
		number,
		Record<PlaceStatGranularity, number[][]>
	> = {};
	private likeStats: Record<
		number,
		Record<PlaceStatGranularity, number[][]>
	> = {};
	private playtimeStats: Record<
		number,
		Record<PlaceStatGranularity, number[][]>
	> = {};

	private _averageTagCCU: Record<
		number,
		Record<CCUDataSegment, Record<PlaceStatGranularity, number[][]>>
	> = {};

	private _averageCreatorCCU: Record<
		number,
		Record<CCUDataSegment, Record<PlaceStatGranularity, number[][]>>
	> = {};

	constructor() {
		makeAutoObservable(this);
	}

	async getPlaceStats(
		placeIds: number[],
		statType: PlaceStatType,
		granularity: PlaceStatGranularity
	) {
		const placesToCollect = this.getPlacesToCollect(
			placeIds,
			statType,
			granularity
		);

		// Data already loaded
		if (!placesToCollect.length) {
			return;
		}

		try {
			const startDate = this.getStartDate(placesToCollect);
			const endDate = getNearestPreviousDate(granularity);
			const url = `${concurrentUsersApi}/${statType}?placeId${statType !== PlaceStatType.PLAYTIME ? 's' : ''}=${placesToCollect.toString()}&granularity=${granularity}&startTime=${startDate.toISOString()}&endTime=${previousEvenTenMinuteDate(endDate).toISOString()}`;
			const response = await httpFetch.GET(url, true, true);

			if (response.ok) {
				const data = PlaceStatsSchema.parse(await response.json());
				const parsedData: Record<number, number[][]> = {};
				for (const placeStat of data.series) {
					parsedData[placeStat.place_id] = placeStat
						? this.parsePlaceStats(placeStat, statType)
						: [];
				}

				runInAction(() => {
					this.setPlaceStats(parsedData, statType, granularity);
				});
			}
		} catch (_) {
			this.setEmptyStats(placesToCollect, statType, granularity);
		}
	}

	placeStat(
		placeId: number,
		statType: PlaceStatType,
		granularity: PlaceStatGranularity
	) {
		let data;

		switch (statType) {
			case PlaceStatType.CCUS:
				data = this.concurrentUserStats[placeId]?.[granularity];
				break;
			case PlaceStatType.RATING:
				data = this.ratingStats[placeId]?.[granularity];
				break;
			case PlaceStatType.VISITS:
				data = this.visitsStats[placeId]?.[granularity];
				break;
			case PlaceStatType.LIKES:
				data = this.likeStats[placeId]?.[granularity];
				break;
			case PlaceStatType.PLAYTIME:
				data = this.playtimeStats[placeId]?.[granularity];
				break;
		}

		// Copy data to prevent making the inner data editable
		return data ? [...data] : undefined;
	}

	async getAverageTagCCU(
		tag: PlaceTag | PopulatedPlaceTag,
		tagExperiences: Experience[],
		granularity: PlaceStatGranularity,
		segment: CCUDataSegment
	) {
		const existingData =
			this._averageTagCCU[tag.id]?.[segment]?.[granularity];
		if (existingData?.length) {
			return existingData;
		}

		let experiences = [...tagExperiences];

		if (segment === CCUDataSegment.TOP_FIVE) {
			experiences = experiences
				.sort((a, b) => b.players_online - a.players_online)
				.slice(0, 5);
		}

		const placesToCollect = experiences.map((exp) => exp.place_id);

		try {
			const startDate = this.getStartDate(placesToCollect);
			const endDate = getNearestPreviousDate(granularity);
			const url = `${concurrentUsersApi}/${PlaceStatType.CCUS}?placeIds=${placesToCollect.toString()}&granularity=${granularity}&startTime=${startDate.toISOString()}&endTime=${previousEvenTenMinuteDate(endDate).toISOString()}&aggregation=average&includeZeros=${segment === CCUDataSegment.ALL}`;
			const response = await httpFetch.GET(url, true, true);

			if (response.ok) {
				const data = PlaceTagStatsSchema.parse(await response.json());

				if (!this._averageTagCCU[tag.id]) {
					this.setEmptyTagCCU(tag.id);
				}

				runInAction(() => {
					this._averageTagCCU[tag.id][segment][granularity] =
						data.series[0].data
							.map((d) => [d[0], parseFloat(d[1].toFixed(1))])
							.sort((a, b) => a[0] - b[0]);
				});
			}
		} catch (err) {
			runInAction(() => {
				if (!this._averageTagCCU[tag.id]) {
					this.setEmptyTagCCU(tag.id);
				}

				this._averageTagCCU[tag.id][segment][granularity] = [];
			});
		}
	}

	private setEmptyTagCCU(tagId: number) {
		runInAction(() => {
			this._averageTagCCU[tagId] = {
				[CCUDataSegment.ALL]: {
					[PlaceStatGranularity.HOUR]: [],
					[PlaceStatGranularity.DAY]: [],
					[PlaceStatGranularity.WEEK]: [],
					[PlaceStatGranularity.MONTH]: [],
				},
				[CCUDataSegment.ACTIVE]: {
					[PlaceStatGranularity.HOUR]: [],
					[PlaceStatGranularity.DAY]: [],
					[PlaceStatGranularity.WEEK]: [],
					[PlaceStatGranularity.MONTH]: [],
				},
				[CCUDataSegment.TOP_FIVE]: {
					[PlaceStatGranularity.HOUR]: [],
					[PlaceStatGranularity.DAY]: [],
					[PlaceStatGranularity.WEEK]: [],
					[PlaceStatGranularity.MONTH]: [],
				},
			};
		});
	}

	averageTagCCU(
		tagId: number,
		granularity: PlaceStatGranularity,
		segment: CCUDataSegment
	) {
		return this._averageTagCCU[tagId]?.[segment]?.[granularity] ?? [];
	}

	async getAverageCreatorCCU(
		creator: Creator,
		creatorExperiences: Experience[],
		granularity: PlaceStatGranularity,
		segment: CCUDataSegment
	) {
		const existingData =
			this._averageCreatorCCU[creator.id]?.[segment]?.[granularity];
		if (existingData?.length) {
			return existingData;
		}

		let experiences = [...creatorExperiences];

		if (segment === CCUDataSegment.TOP_FIVE) {
			experiences = experiences
				.sort((a, b) => b.players_online - a.players_online)
				.slice(0, 5);
		}

		const placesToCollect = experiences.map((exp) => exp.place_id);

		try {
			const startDate = this.getStartDate(placesToCollect);
			const endDate = getNearestPreviousDate(granularity);
			const url = `${concurrentUsersApi}/${PlaceStatType.CCUS}?placeIds=${placesToCollect.toString()}&granularity=${granularity}&startTime=${startDate.toISOString()}&endTime=${previousEvenTenMinuteDate(endDate).toISOString()}&aggregation=average&includeZeros=${segment === CCUDataSegment.ALL}`;
			const response = await httpFetch.GET(url, true, true);

			if (response.ok) {
				const data = PlaceTagStatsSchema.parse(await response.json());

				if (!this._averageCreatorCCU[creator.id]) {
					this.setEmptyCreatorCCU(creator.id);
				}

				runInAction(() => {
					this._averageCreatorCCU[creator.id][segment][granularity] =
						data.series[0].data
							.map((d) => [d[0], parseFloat(d[1].toFixed(1))])
							.sort((a, b) => a[0] - b[0]);
				});
			}
		} catch (err) {
			runInAction(() => {
				if (!this._averageCreatorCCU[creator.id]) {
					this.setEmptyCreatorCCU(creator.id);
				}

				this._averageCreatorCCU[creator.id][segment][granularity] = [];
			});
		}
	}

	private setEmptyCreatorCCU(creatorId: number) {
		runInAction(() => {
			this._averageCreatorCCU[creatorId] = {
				[CCUDataSegment.ALL]: {
					[PlaceStatGranularity.HOUR]: [],
					[PlaceStatGranularity.DAY]: [],
					[PlaceStatGranularity.WEEK]: [],
					[PlaceStatGranularity.MONTH]: [],
				},
				[CCUDataSegment.ACTIVE]: {
					[PlaceStatGranularity.HOUR]: [],
					[PlaceStatGranularity.DAY]: [],
					[PlaceStatGranularity.WEEK]: [],
					[PlaceStatGranularity.MONTH]: [],
				},
				[CCUDataSegment.TOP_FIVE]: {
					[PlaceStatGranularity.HOUR]: [],
					[PlaceStatGranularity.DAY]: [],
					[PlaceStatGranularity.WEEK]: [],
					[PlaceStatGranularity.MONTH]: [],
				},
			};
		});
	}

	averageCreatorCCU(
		creatorId: number,
		granularity: PlaceStatGranularity,
		segment: CCUDataSegment
	) {
		return (
			this._averageCreatorCCU[creatorId]?.[segment]?.[granularity] ?? []
		);
	}

	async getAverageCCU(
		experienceIds: number[],
		granularity: PlaceStatGranularity,
		segment: CCUDataSegment
	): Promise<number[][]> {
		try {
			const startDate = this.getStartDate(experienceIds);
			const endDate = getNearestPreviousDate(granularity);
			const url = `${concurrentUsersApi}/${PlaceStatType.CCUS}?placeIds=${experienceIds.toString()}&granularity=${granularity}&startTime=${startDate.toISOString()}&endTime=${previousEvenTenMinuteDate(endDate).toISOString()}&aggregation=average&includeZeros=${segment === CCUDataSegment.ALL}`;
			const response = await httpFetch.GET(url, true, true);

			if (response.ok) {
				const parsedData = PlaceTagStatsSchema.parse(
					await response.json()
				);
				return parsedData.series[0].data
					.map((d) => [d[0], parseFloat(d[1].toFixed(1))])
					.sort((a, b) => a[0] - b[0]);
			}
		} catch (_) {
			// Send back empty array
		}

		return [];
	}

	async getDailyCCU(placeIds: number[]) {
		const lastDayEnd = new Date();
		lastDayEnd.setUTCDate(lastDayEnd.getDate() - 1);
		lastDayEnd.setUTCHours(23, 59, 59, 0);
		const lastDayStart = new Date();
		lastDayStart.setUTCDate(lastDayStart.getDate() - 1);
		lastDayStart.setUTCHours(0, 0, 0, 0);
		const url = `${concurrentUsersApi}/${PlaceStatType.CCUS}?placeIds=${placeIds.toString()}&granularity=${PlaceStatGranularity.DAY}&startTime=${lastDayStart.toISOString()}&endTime=${lastDayEnd.toISOString()}`;
		const response = await httpFetch.GET(url, true, true);
		if (response.ok) {
			const dailyCCU: Record<number, number> = {};
			const data = PlaceStatsSchema.parse(await response.json());
			for (const ccu of data.series) {
				dailyCCU[ccu.place_id] = ccu.data[ccu.data.length - 1][1];
			}

			return dailyCCU;
		}

		return {};
	}

	async getWeeklyCCU(placeIds: number[]) {
		const currentDate = new Date();
		const dayOfWeek = currentDate.getDay();
		const daysToSubtract = (dayOfWeek + 6) % 7;
		const endOfLastWeek = new Date(
			currentDate.getTime() - daysToSubtract * 24 * 60 * 60 * 1000
		);

		const startOfLastWeek = new Date(
			endOfLastWeek.getTime() - 6 * 24 * 60 * 60 * 1000
		);
		startOfLastWeek.setUTCHours(0, 0, 0, 0);
		startOfLastWeek.setUTCDate(startOfLastWeek.getDate() - 1);
		endOfLastWeek.setUTCHours(23, 59, 59, 0);
		endOfLastWeek.setUTCDate(endOfLastWeek.getDate() - 1);

		const url = `${concurrentUsersApi}/${PlaceStatType.CCUS}?placeIds=${placeIds.toString()}&granularity=${PlaceStatGranularity.WEEK}&startTime=${startOfLastWeek.toISOString()}&endTime=${endOfLastWeek.toISOString()}`;
		const response = await httpFetch.GET(url, true, true);
		if (response.ok) {
			const weeklyCCU: Record<number, number> = {};
			const data = PlaceStatsSchema.parse(await response.json());
			for (const ccu of data.series) {
				weeklyCCU[ccu.place_id] = ccu.data[ccu.data.length - 1][1];
			}

			return weeklyCCU;
		}

		return {};
	}

	async getMonthlyCCU(placeIds: number[]) {
		const currentDate = new Date();
		currentDate.setUTCDate(1);
		currentDate.setUTCHours(0, 0, 0, 0);
		const startOfLastMonth = new Date(
			currentDate.getTime() - 24 * 60 * 60 * 1000
		);
		startOfLastMonth.setUTCDate(1);
		const endOfLastMonth = new Date(
			startOfLastMonth.getFullYear(),
			startOfLastMonth.getMonth() + 1,
			0
		);
		endOfLastMonth.setUTCHours(23, 59, 59, 0);

		const url = `${concurrentUsersApi}/${PlaceStatType.CCUS}?placeIds=${placeIds.toString()}&granularity=${PlaceStatGranularity.MONTH}&startTime=${startOfLastMonth.toISOString()}&endTime=${endOfLastMonth.toISOString()}`;
		const response = await httpFetch.GET(url, true, true);
		if (response.ok) {
			const monthlyCCU: Record<number, number> = {};
			const data = PlaceStatsSchema.parse(await response.json());
			for (const ccu of data.series) {
				monthlyCCU[ccu.place_id] = ccu.data[ccu.data.length - 1][1];
			}

			return monthlyCCU;
		}

		return {};
	}

	private getPlacesToCollect(
		placeIds: number[],
		statType: PlaceStatType,
		granularity: PlaceStatGranularity
	) {
		switch (statType) {
			case PlaceStatType.CCUS:
				return placeIds.filter((id) => {
					return !this.concurrentUserStats[id]?.[granularity]?.length;
				});
			case PlaceStatType.VISITS:
				return placeIds.filter((id) => {
					return !this.visitsStats[id]?.[granularity]?.length;
				});
			case PlaceStatType.RATING:
				return placeIds.filter((id) => {
					return !this.ratingStats[id]?.[granularity]?.length;
				});
			case PlaceStatType.LIKES:
				return placeIds.filter((id) => {
					return !this.likeStats[id]?.[granularity]?.length;
				});
			case PlaceStatType.PLAYTIME:
				return placeIds.filter((id) => {
					return !this.playtimeStats[id]?.[granularity]?.length;
				});
		}
	}

	private parsePlaceStats(
		placeStat: PlaceStatsSeries,
		statType: PlaceStatType
	) {
		switch (statType) {
			case PlaceStatType.CCUS:
			case PlaceStatType.VISITS:
			case PlaceStatType.PLAYTIME:
				return placeStat.data.map((dataPoint) => {
					return [dataPoint[0], Math.floor(dataPoint[1])];
				});
			case PlaceStatType.RATING:
				return placeStat.data.map((dataPoint) => {
					const secondData = (dataPoint[1] * 100).toFixed(4);
					return [dataPoint[0], +secondData];
				});
			case PlaceStatType.LIKES:
				return placeStat.data.map((dataPoint) => {
					return [
						dataPoint[0],
						Math.floor(dataPoint[1]),
						Math.abs(Math.floor(dataPoint[2])) * -1,
					];
				});
		}
	}

	private setPlaceStats(
		parsedData: Record<number, number[][]>,
		statType: PlaceStatType,
		timeFrame: PlaceStatGranularity
	) {
		switch (statType) {
			case PlaceStatType.CCUS:
				for (const [placeId, placeStat] of Object.entries(parsedData)) {
					this.concurrentUserStats[+placeId] = {
						...this.concurrentUserStats[+placeId],
						[timeFrame]: placeStat,
					};
				}
				break;
			case PlaceStatType.VISITS:
				for (const [placeId, placeStat] of Object.entries(parsedData)) {
					this.visitsStats[+placeId] = {
						...this.visitsStats[+placeId],
						[timeFrame]: placeStat,
					};
				}
				break;
			case PlaceStatType.RATING:
				for (const [placeId, placeStat] of Object.entries(parsedData)) {
					this.ratingStats[+placeId] = {
						...this.ratingStats[+placeId],
						[timeFrame]: placeStat,
					};
				}
				break;
			case PlaceStatType.LIKES:
				for (const [placeId, placeStat] of Object.entries(parsedData)) {
					this.likeStats[+placeId] = {
						...this.likeStats[+placeId],
						[timeFrame]: placeStat,
					};
				}
				break;
			case PlaceStatType.PLAYTIME:
				for (const [placeId, placeStat] of Object.entries(parsedData)) {
					this.playtimeStats[+placeId] = {
						...this.playtimeStats[+placeId],
						[timeFrame]: placeStat,
					};
				}
				break;
		}
	}

	private setEmptyStats(
		placeIds: number[],
		statType: PlaceStatType,
		timeFrame: PlaceStatGranularity
	) {
		switch (statType) {
			case PlaceStatType.CCUS:
				for (const placeId of placeIds) {
					this.concurrentUserStats[+placeId] = {
						...this.concurrentUserStats[+placeId],
						[timeFrame]: [],
					};
				}
				break;
			case PlaceStatType.VISITS:
				for (const placeId of placeIds) {
					this.visitsStats[+placeId] = {
						...this.visitsStats[+placeId],
						[timeFrame]: [],
					};
				}
				break;
			case PlaceStatType.RATING:
				for (const placeId of placeIds) {
					this.ratingStats[+placeId] = {
						...this.ratingStats[+placeId],
						[timeFrame]: [],
					};
				}
				break;
			case PlaceStatType.LIKES:
				for (const placeId of placeIds) {
					this.likeStats[+placeId] = {
						...this.likeStats[+placeId],
						[timeFrame]: [],
					};
				}
				break;
			case PlaceStatType.PLAYTIME:
				for (const placeId of placeIds) {
					this.playtimeStats[+placeId] = {
						...this.playtimeStats[+placeId],
						[timeFrame]: [],
					};
				}
				break;
		}
	}

	private getStartDate(placeIds: number[]): Date {
		const date = new Date();
		date.setUTCHours(0, 0, 0, 0);

		let oldestPlace: FullExperience | undefined;
		for (const placeId of placeIds) {
			const p = experienceStore.getFullExperienceById(placeId);
			if (!p) {
				continue;
			}

			if (!oldestPlace || p.created < oldestPlace.created) {
				oldestPlace = p;
			}
		}

		date.setUTCDate(new Date().getDate() - 5000);
		return oldestPlace?.created ?? date;
	}
}

const placeStatsStore = new PlaceStatsStore();
export default placeStatsStore;
