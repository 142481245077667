import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import { useLoaderData, useLocation } from 'react-router-dom';
import placeTagStore from '@store/place-tag-store.ts';
import ExperienceDetailsInfo from '@views/experience-details/view-components/experience-details-info.tsx';
import { ExperienceDetailsLoaderReturn } from '@views/experience-details/experience-details-loader.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import ExperienceLoadingTimesList from '@components/data/lightdash-dashboards/experience-loading-times-list.ts';
import { DashboardInfoType } from '@components/data/lightdash-dashboards/dashboard-info.ts';
import ExperienceDetailsRanking from '@views/experience-details/view-components/experience-details-ranking.tsx';
import ChartColors from '@components/core/chart/chart-colors.ts';
import ExperienceCcuAndVisits from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits.tsx';
import ExperienceDetailsRankingTable from '@views/experience-details/view-components/experience-details-ranking-table.tsx';
import { useEffect, useState } from 'react';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import { ExperienceStatus } from '@/schemas/experience-schema.ts';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import { ExperienceCcuAndVisitsState } from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits-state.ts';
import viewStore from '@store/view-store.ts';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import ClassString from '@utils/class-string.ts';
import ExperienceDetailsInfoCompact from '@views/experience-details/view-components/experience-details-info-compact.tsx';
import ExperienceDetailsOptions from '@views/experience-details/view-components/experience-details-options.tsx';
import ExperienceDetailsTagAdder from '@views/experience-details/view-components/experience-details-tag-adder.tsx';
import ExperienceDetailsCompactHeaderBanner from '@views/experience-details/view-components/experience-details-compact-header-banner.tsx';

const ccuTabs = [
	{
		key: ExperienceCcuAndVisitsState.CCU,
		title: 'CCU',
	},
	{
		key: ExperienceCcuAndVisitsState.VISITS,
		title: 'Visits',
	},
	{
		key: ExperienceCcuAndVisitsState.BOTH,
		title: 'CCU & Visits',
	},
];

const playtimeOptions = {
	availableGranularities: [
		PlaceStatGranularity.HOUR,
		PlaceStatGranularity.DAY,
		PlaceStatGranularity.WEEK,
	],
	defaultGranularity: PlaceStatGranularity.HOUR,
	fill: true,
	selectedRange: 1,
	lineColor: ChartColors()[2],
};

const ExperienceDetails = observer(function ExperienceDetails() {
	const locationState = useLocation().state as LocationState;

	const { experience } = useLoaderData() as ExperienceDetailsLoaderReturn;
	const experienceArray = [experience];

	const experienceTags = placeTagStore.getGlobalPlaceTagsByPlaceId(
		experience?.place_id
	);

	const [currentRankingsTab, setCurrentRankingsTab] = useState('');

	const [ccuChartState, setCCUChartState] = useState(
		ExperienceCcuAndVisitsState.CCU
	);

	const dashboards: DashboardInfoType[] = [
		{
			type: DashboardType.GAME_SPECIFIC_OVERVIEW,
			placeId: experience.place_id,
		},
	];

	if (ExperienceLoadingTimesList.includes(experience.place_id)) {
		dashboards.push({
			type: DashboardType.EXPERIENCE_LOADING_TIMES,
			experienceCanonicalName: experience.canonical_name,
		});
	}

	useEffect(() => {
		postHogService.capture({
			type: PostHogEventType.NavigateToExperience,
			from: locationState?.from.pathname ?? 'Direct Navigation',
			experience: experience?.canonical_name ?? '?',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (viewStore.loadingViewData && !experienceRankingStore.loading) {
			viewStore.loadingViewData = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewStore.loadingViewData, experienceRankingStore.loading]);

	return (
		<View
			viewInfo={{
				title: `${experience?.name ?? 'Experience'}`,
				icon: IconEnum.PLACE,
				breadCrumbs: [{ title: 'Experiences', route: '/experiences' }],
				metaTagDescription: `Track real-time stats for ${experience?.name} on Roblox. View concurrent players, rankings, playtime, like/dislike ratios, image update history, and more.`,
				metaTagImageUrl: experience?.thumbnails?.banner
					? `https://tr.rbxcdn.com/180DAY-${experience.thumbnails.banner}/384/216/Image/Webp/noFilter`
					: undefined,
			}}
			loading={!experience}
			key={`experience-view-${experience.place_id}`}
			headerChildren={
				<>
					{!!experience && (
						<ExperienceDetailsCompactHeaderBanner
							experience={experience}
						/>
					)}
				</>
			}
			headerClass={'lg:hidden -mb-6'}
		>
			{!!experience && (
				<>
					<ViewPanel options={{ container: true }}>
						<div
							className={ClassString({
								static: 'flex justify-center items-center gap-4 h-full w-full lg:w-auto ',
								dynamic: {},
							})}
						>
							<ExperienceDetailsInfo
								experience={experience}
								tags={experienceTags}
								className={'hidden lg:flex'}
							/>
						</div>
					</ViewPanel>
					<div
						className={
							'lg:hidden w-full flex flex-col justify-center items-center gap-6'
						}
					>
						<ExperienceDetailsInfoCompact
							experience={experience}
							tags={experienceTags}
						/>

						<ExperienceDetailsOptions experience={experience} />
					</div>

					<ViewTabPanel tabs={ccuTabs} onTabChange={setCCUChartState}>
						<ExperienceCcuAndVisits
							place={experience}
							state={ccuChartState}
						/>
					</ViewTabPanel>

					<ViewPanel>
						<PlaceStats
							places={experienceArray}
							statType={PlaceStatType.PLAYTIME}
							title={'Average Playtime (experimental)'}
							options={playtimeOptions}
						/>
					</ViewPanel>

					<ViewTabPanel
						tabs={[
							{
								key: 'ranking',
								title: 'Rankings',
								children: (
									<ExperienceDetailsRanking
										placeId={experience.place_id}
									/>
								),
							},
							{
								key: 'milestones',
								title: 'Ranking Milestones',
								children: (
									<ExperienceDetailsRankingTable
										placeId={experience.place_id}
										isHidden={
											currentRankingsTab !== 'milestones'
										}
									/>
								),
							},
						]}
						onTabChange={setCurrentRankingsTab}
					/>

					<ViewTabPanel
						tabs={[
							{
								key: 'likes',
								title: 'Likes',
								children: (
									<PlaceStats
										places={[experience]}
										statType={PlaceStatType.LIKES}
										title={'Likes'}
										options={{
											selectedRange:
												experience.play_status !==
												ExperienceStatus.PLAYABLE
													? 7
													: 2,
										}}
									/>
								),
							},
							{
								key: 'like_percentage',
								title: 'Like Percentage',
								children: (
									<PlaceStats
										places={[experience]}
										statType={PlaceStatType.RATING}
										title={'Like Percentage'}
										options={{
											fill: true,
											availableGranularities: [
												PlaceStatGranularity.DAY,
											],
											yPercentage: true,
											lineColor: ChartColors()[2],
										}}
									/>
								),
							},
						]}
					/>

					<LightdashDashboards dashboards={dashboards} />

					<ViewPanel
						className={ClassString({
							dynamic: {
								'max-w-screen-lg': !authStore.havePermission(
									UserPermission.EDIT_TAG
								),
								'max-w-screen-xl': authStore.havePermission(
									UserPermission.EDIT_TAG
								),
							},
						})}
					>
						{authStore.havePermission(UserPermission.EDIT_TAG) && (
							<ExperienceDetailsTagAdder
								experienceTags={experienceTags}
								experience={experience}
							/>
						)}
					</ViewPanel>
				</>
			)}
		</View>
	);
});

export default ExperienceDetails;
