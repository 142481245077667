import Nav from '@components/navigation/nav/nav.tsx';
import Toast from '@components/service/toast/toast.tsx';
import { Outlet } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import NavSidebar from '@components/navigation/nav/nav-sidebar.tsx';
import { observer } from 'mobx-react-lite';
import ConditionalStrictMode from '@/conditional-strict-mode.tsx';
import usePageViewTracker from '@hooks/use-page-view-tracker.tsx';
import ClassString from '@utils/class-string.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import authStore from '@store/auth-store.ts';
import Modal from '@components/modal/modal.tsx';
import OptionsMenuContainer from '@components/core/options-menu/options-menu-container.tsx';

const App = observer(function App() {
	usePageViewTracker();

	// Service worker update checker
	useEffect(() => {
		if ('serviceWorker' in navigator) {
			// Manual check for updates every 5 minutes
			const checkForUpdates = () => {
				void navigator.serviceWorker
					.getRegistration()
					.then((registration) => {
						if (registration) {
							void registration.update();
						}
					});
			};
			const intervalId = setInterval(checkForUpdates, 5 * 60 * 1000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, []);

	return (
		<ConditionalStrictMode>
			<div
				className={ClassString({
					static: ' relative',
					dynamic: {
						'lg:pl-sidebar':
							!navSidebarStore.minimal && authStore.isLoggedIn,
						'lg:pl-24': navSidebarStore.minimal,
					},
				})}
			>
				<NavSidebar />
				<Nav />
				<Suspense>
					<Outlet />
				</Suspense>
				<Toast />
				<Modal />
				<OptionsMenuContainer />
			</div>
		</ConditionalStrictMode>
	);
});

export default App;
