import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import experienceStore from '@store/experience-store.ts';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import { useLoaderData } from 'react-router-dom';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExperienceTable from '@components/tables/experience-table.tsx';
import { useEffect, useState } from 'react';
import { reaction } from 'mobx';

const capitalizeWords = (str: string) => {
	return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const SubGenre = observer(function SubGenre() {
	const { genre, subGenre } = useLoaderData() as {
		genre: string;
		subGenre: string;
	};

	const [initialized, setInitialized] = useState(false);
	const [experiences, setExperiences] = useState<SlimExperience[]>([]);

	useEffect(() => {
		const setGenreData = () => {
			if (initialized) {
				return;
			}

			setExperiences(
				experienceStore.getExperiencesBySubGenre(genre, subGenre)
			);

			setInitialized(true);
		};

		const disposer = reaction(
			() => experienceStore.getSubGenres(genre), // Track only this specific value
			() => {
				setGenreData();
			}
		);

		if (experienceStore.slimExperiences.length) {
			setGenreData();
		}

		return () => disposer(); // Cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: capitalizeWords(subGenre),
				icon: IconEnum.GENRE,
				iconSize: '30px',
				breadCrumbs: [
					{ title: 'Genres', route: '/genres' },
					{
						title: capitalizeWords(genre),
						route: `/genres/${genre}`,
					},
				],
			}}
			loading={!initialized}
			hideHeader
		>
			<ViewPanel>
				<ExperienceTable experiences={experiences} />
			</ViewPanel>
		</View>
	);
});

export default SubGenre;
