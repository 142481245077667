import { observer } from 'mobx-react-lite';
import { FullExperience } from '@/schemas/experience-schema.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExpansionButton from '@components/core/expansion-button/expansion-button.tsx';
import Icon from '@components/core/icon/icon.tsx';
import copyService from '@services/copy-service.ts';
import { useNavigate } from 'react-router-dom';
import modalStore from '@store/modal-store.ts';
import ExperienceDetailsSetReleaseDateModal from '@views/experience-details/view-components/experience-details-set-release-date-modal.tsx';
import authStore from '@store/auth-store.ts';
import { UserRole } from '@/schemas/user-schema.ts';
import ClassString from '@utils/class-string.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';

interface ExperienceDetailsOptionsProps {
	experience: FullExperience;
	className?: string;
}

const robloxPlaceUrl = 'https://www.roblox.com/games/';

const ExperienceDetailsOptions = observer(function ExperienceDetailsOptions(
	props: ExperienceDetailsOptionsProps
) {
	const navigate = useNavigate();
	const screenBreakpoint = useScreenBreakpoint();

	const releaseDateSet = props.experience.released.getFullYear() >= 2;

	return (
		<div
			className={ClassString({
				static: 'h-full flex justify-center items-center gap-2 min-w-experience-thumbnail max-w-experience-thumbnail',
				dynamic: {},
				custom: props.className,
			})}
		>
			<ExpansionButton
				title={'Experience Options'}
				subMenuButtons={[
					...(screenBreakpoint <= ScreenBreakpoint.LG
						? [
								{
									title: (
										<div
											className={
												'flex justify-center items-center gap-2 px-4'
											}
										>
											{'View on roblox.com'}
											<Icon
												icon={IconEnum.EXTERNAL_LINK}
												size={'1.25em'}
											/>
										</div>
									),
									onClick: () => {
										open(
											`${robloxPlaceUrl}${props.experience.place_id}`,
											'_blank'
										);
									},
								},
							]
						: []),
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Launch Experience'}
								<Icon icon={IconEnum.PLAY} size={'1.5em'} />
							</div>
						),
						onClick: () => {
							open(
								`roblox://placeId=${props.experience.place_id}`,
								'_blank'
							);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Compare To Experience'}
								<Icon icon={IconEnum.COMPARE} size={'1.5rem'} />
							</div>
						),
						onClick: () => {
							navigate(
								`/compare/experiences#exp=${props.experience.place_id}`
							);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Compare To Tag'}
								<Icon icon={IconEnum.COMPARE} size={'1.5rem'} />
							</div>
						),
						onClick: () => {
							navigate(
								`/compare/experience-tag#exp=${props.experience.place_id}`
							);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4 group/yt w-full h-full'
								}
							>
								{'Find on YouTube'}
								<div
									className={
										'flex justify-center items-center'
									}
								>
									<Icon
										icon={IconEnum.VIDEO}
										size={'1.5rem'}
									/>
								</div>
							</div>
						),
						onClick: () => {
							open(
								`https://www.youtube.com/results?search_query=roblox+${props.experience.canonical_name.toLowerCase().replaceAll(' ', '+')}`,
								'_blank'
							);
						},
					},
					...(releaseDateSet ||
					!authStore.haveUserRole([UserRole.EDITOR, UserRole.ADMIN])
						? []
						: [
								{
									title: (
										<div
											className={
												'flex justify-center items-center gap-2 px-4'
											}
										>
											{'Set Release Date'}
											<Icon
												icon={IconEnum.CALENDAR}
												size={'1.5rem'}
											/>
										</div>
									),
									onClick: () => {
										modalStore.open(
											<ExperienceDetailsSetReleaseDateModal
												experience={props.experience}
											/>
										);
									},
								},
							]),
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Copy Experience Id'}
								<Icon icon={IconEnum.COPY} />
							</div>
						),
						onClick: () => {
							copyService.experienceId(props.experience);
						},
					},
					{
						title: (
							<div
								className={
									'flex justify-center items-center gap-2 px-4'
								}
							>
								{'Copy Universe Id'}
								<Icon icon={IconEnum.COPY} />
							</div>
						),
						onClick: () => {
							copyService.experienceUniverseId(props.experience);
						},
					},
				]}
			/>
		</div>
	);
});

export default ExperienceDetailsOptions;
