import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import viewStore, { ViewInfo } from '@store/view-store.ts';
import ClassString from '@utils/class-string.ts';
import ScrollProvider from '@components/providers/scroll-provider.tsx';
import { useNavigation } from 'react-router-dom';

interface ViewProps {
	children: React.ReactNode;
	viewInfo?: ViewInfo;
	hideHeader?: boolean;
	headerChildren?: React.ReactNode;
	headerClass?: string;
	className?: string;
	fullHeight?: boolean;
	loading?: boolean;
	center?: boolean;
}

const defaultMetaTagTitle = 'Tools by The Gang';
const defaultMetaTagDescription =
	'Track real-time Roblox data with ease. Search, group, and compare games by concurrent users, playtime, rankings, and more.';
const defaultMetaTagImageUrl = 'https://tools.thegang.io/the_gang_logo.png';

const View = observer(function View(props: ViewProps) {
	const { state } = useNavigation();

	const signInScreen =
		window.location.href.endsWith('sign-in') ||
		window.location.href.endsWith('sign-up');

	useEffect(() => {
		const title = props.viewInfo?.title
			? `${props.viewInfo.title} | Tools`
			: defaultMetaTagTitle;
		const description = props.viewInfo?.metaTagDescription
			? props.viewInfo.metaTagDescription
			: defaultMetaTagDescription;
		const imageUrl = props.viewInfo?.metaTagImageUrl
			? props.viewInfo.metaTagImageUrl
			: defaultMetaTagImageUrl;

		document.title = title;

		document
			.querySelector('meta[name="title"]')
			?.setAttribute('content', title);
		document
			.querySelector('meta[name="og:title"]')
			?.setAttribute('content', title);
		document
			.querySelector('meta[name="twitter:title"]')
			?.setAttribute('content', title);

		document
			.querySelector('meta[name="description"]')
			?.setAttribute('content', description);
		document
			.querySelector('meta[name="og:description"]')
			?.setAttribute('content', description);
		document
			.querySelector('meta[name="twitter:description"]')
			?.setAttribute('content', description);

		document
			.querySelector('meta[property="og:image"]')
			?.setAttribute('content', imageUrl);
		document
			.querySelector('meta[name="twitter:image"]')
			?.setAttribute('content', imageUrl);

		document
			.querySelector('meta[name="robots"]')
			?.setAttribute(
				'content',
				viewStore.viewInfo?.robotNoIndex
					? 'noindex,nofollow'
					: 'index,follow'
			);

		document
			.querySelector('link[rel="canonical"]')
			?.setAttribute(
				'href',
				`https://tools.thegang.io${window.location.pathname}`
			);

		viewStore.setViewInfo(props.viewInfo);
	}, [props.viewInfo]);

	return (
		<ScrollProvider>
			<div
				className={ClassString({
					static: 'mx-auto max-w-screen-2xl px-2 lg:px-6',
					dynamic: {
						'h-full': props.fullHeight ?? props.loading,
						'w-screen h-screen max-w-screen mx-0 p-0': signInScreen,
					},
					custom: props.className,
				})}
			>
				<div
					className={ClassString({
						static: 'flex flex-wrap justify-center gap-6 w-full',
						dynamic: {
							'h-full flex-col': props.fullHeight,
							'h-full items-center': !!props.loading,
							'flex-row items-center': state === 'loading',
							'items-center': props.center,
							'w-full h-screen max-h-screen flex-col items-center':
								signInScreen,
						},
					})}
				>
					{!props.loading && !props.hideHeader && (
						<div
							className={ClassString({
								static: 'flex justify-between flex-wrap items-center w-full -my-2',
								dynamic: {},
								custom: props.headerClass,
							})}
						>
							{props.headerChildren}
						</div>
					)}

					{!props.loading && <>{props.children}</>}

					{props.loading && (
						<div className={'flex justify-center my-auto'}>
							<LoadingIndicator />
						</div>
					)}
				</div>
			</div>
		</ScrollProvider>
	);
});

export default View;
