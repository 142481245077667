import { observer } from 'mobx-react-lite';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import { ValueFormatterParams } from 'ag-grid-community';

interface Genre {
	name: string;
	experiences: SlimExperience[];
	mainGenre: string;
}

interface GenreProps {
	genres: Genre[];
	genreColumnHeader: string;
	subGenres?: boolean;
}

const GenreTable = observer(function GenreTable(props: GenreProps) {
	const columnDefs: CustomColDef<Genre>[] = [
		{
			headerName: props.genreColumnHeader,
			field: 'name',
			resizable: false,
			cellRenderer: (params: CustomCellRendererProps) => {
				const genre = params.node.data as Genre;
				return (
					<NavigationButton
						path={
							props.subGenres
								? `/genres/${genre.mainGenre}/${genre.name.toLowerCase()}`
								: `/genres/${genre.name.toLowerCase()}`
						}
						direction={NavigationButtonEnum.FORWARD}
						title={genre.name}
						className={'capitalize'}
					/>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '25px',
				paddingRight: '0',
			},
			minWidth: 300,
			context: {
				priority: 1,
			},
			flex: 1,
			sort: 'asc',
		},
		{
			headerName: 'Experiences',
			field: 'experiences',
			resizable: false,
			valueFormatter: (
				params: ValueFormatterParams<Genre, SlimExperience[]>
			) => {
				return params.value ? params.value.length.toString() : '0';
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '25px',
				paddingRight: '0',
			},
			minWidth: 200,
			maxWidth: 200,
			context: {
				priority: 2,
			},
			flex: 1,
		},
	];

	return (
		<TGSTable
			columnDefs={columnDefs}
			rowData={props.genres}
			tableName={'genre-table'}
			rowHeight={50}
		/>
	);
});

export default GenreTable;
