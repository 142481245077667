import { z } from 'zod';

export interface EventStatsResponse {
	place_id: number;
	num_events: number;
	date: string; // Date string
}

export const EventStatsSchema: z.ZodType<EventStatsResponse> = z.object({
	place_id: z.number(),
	num_events: z.number(),
	date: z.string(),
});

export const EventStatsListSchema: z.ZodType<EventStatsResponse[]> =
	z.array(EventStatsSchema);

export interface EventStats {
	place_id: number;
	num_events: number;
	date: Date;
}
