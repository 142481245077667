import { observer } from 'mobx-react-lite';
import ModalBase from '@components/modal/modal-base.tsx';
import DatePicker from '@components/core/date-picker/date-picker.tsx';
import IsValidDate from '@utils/is-valid-date.ts';
import experienceStore from '@store/experience-store.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import { useState } from 'react';

interface ExperienceDetailsSetReleaseDateModalProps {
	experience: FullExperience;
}

const ExperienceDetailsSetReleaseDateModal = observer(
	function ExperienceDetailsSetReleaseDateModal(
		props: ExperienceDetailsSetReleaseDateModalProps
	) {
		const releaseDateSet = props.experience.released.getFullYear() >= 2;
		const [releaseDate, setReleaseDate] = useState(
			releaseDateSet
				? props.experience.released.toLocaleDateString()
				: '-'
		);

		return (
			<ModalBase
				title={`Set release date for  "${props.experience.canonical_name}"`}
				content={{
					children: (
						<>
							<div className={'my-4'}>
								<DatePicker
									value={releaseDate}
									onChange={(change) => {
										setReleaseDate(change);
									}}
								/>
							</div>
						</>
					),
				}}
				onConfirm={() => {
					let newDate: Date;

					if (releaseDate && IsValidDate(releaseDate)) {
						newDate = new Date(releaseDate);
					} else {
						newDate = new Date('0001-01-01T00:00:00Z');
					}

					if (
						newDate.getTime() !==
						props.experience.released.getTime()
					) {
						void experienceStore.updateReleaseDate(
							props.experience,
							newDate
						);
					}
				}}
				options={{
					confirmButtonTitle: 'Set Release Date',
				}}
			/>
		);
	}
);

export default ExperienceDetailsSetReleaseDateModal;
