import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import authStore from '@store/auth-store.ts';
import InputText from '@components/core/input-text/input-text.tsx';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import View from '@components/view/view.tsx';
import { INPUT_TEXT_TYPES } from '@components/core/input-text/input-text-enum.ts';
import Form from '@components/core/form/form.tsx';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { CredentialResponse } from 'google-one-tap';
import ViewPanel from '@components/view/view-panel.tsx';
import LogoBlack from '@assets/logo_black.svg';
import ClassString from '@utils/class-string.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';

const SignIn = observer(function SignIn() {
	const screenBreakpoint = useScreenBreakpoint();
	const [loadingGoogleSSO, setLoadingGoogleSSO] = useState(true);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const locationState = useLocation().state as LocationState;
	const navigateTo = locationState?.from
		? `${locationState.from.pathname}`
		: '/';

	const ref = useRef<HTMLDivElement>(null);

	const handleLogin = async () => {
		try {
			setLoading(true);
			await authStore.login(email, password);
			navigate(navigateTo);
		} catch (_) {
			setLoading(false);
		}
	};

	const handleGoogleLogin = async (response: CredentialResponse) => {
		try {
			setLoading(true);
			await authStore.googleLogin(response);
			navigate(navigateTo);
		} catch (_) {
			setLoading(false);
		}
	};

	const initializeGSI = () => {
		window.google.accounts.id.initialize({
			client_id: import.meta.env.VITE_GOGGLE_OAUTH_CLIENT_ID as string,
			callback: (response: CredentialResponse) => {
				void handleGoogleLogin(response);
			},
		});

		if (ref.current) {
			window.google.accounts.id.renderButton(ref.current, {
				theme: 'outline',
				size: 'large',
				shape: 'pill',
				locale: 'en-EN',
			});

			// Wait for button to potentially populate a logged in google session
			setTimeout(() => {
				setLoadingGoogleSSO(false);
			}, 1800);
		}
	};

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.onload = initializeGSI;
		script.async = true;
		document.head.appendChild(script);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{ title: 'Sign In' }}
			className={'bg-white sm:bg-transparent'}
			hideHeader
		>
			<div className={'absolute top-3 left-3 max-w-24'}>
				<img src={LogoBlack} alt={'logo-full'} draggable="false" />
			</div>

			<ViewPanel
				className={'max-w-md'}
				options={{ container: screenBreakpoint <= ScreenBreakpoint.SM }}
			>
				<div
					className={ClassString({
						static: 'flex flex-col justify-center items-center p-4',
						dynamic: {
							'opacity-0': loading,
						},
					})}
				>
					<div
						className={
							'text-4xl font-bold tracking-wider pl-2 select-none mb-6'
						}
					>
						<span>←</span>
						<span className={'px-1'}>TOOLS</span>
						<span>→</span>
					</div>

					<div className={'w-full text-2xl mb-1 font-semibold'}>
						Sign In
					</div>
					<div className={'w-full mb-6'}>
						Explore comprehensive real-time Roblox data
					</div>

					<Form
						className={
							'flex flex-col justify-center items-center w-full'
						}
						onSubmit={() => {
							void handleLogin();
						}}
					>
						<InputText
							label={'Email'}
							value={email}
							onInput={(event) => setEmail(event.target.value)}
							type={INPUT_TEXT_TYPES.EMAIL}
							options={{ width: '100%', large: true }}
						/>

						<InputText
							label={'Password'}
							value={password}
							onInput={(event) => setPassword(event.target.value)}
							type={INPUT_TEXT_TYPES.PASSWORD}
							options={{ width: '100%', large: true }}
						/>

						<Button
							title={'Sign In'}
							buttonStyle={BUTTON_STYLE.PRIMARY}
							className={'mt-2 w-full py-6 rounded-full max-w-80'}
						/>
					</Form>

					<div
						className={
							'flex justify-center items-center mt-5 mb-4 text-gray-400 w-full'
						}
					>
						<div
							className={
								'border-b border-x-0 border-t-0 border-gray-400 border-solid w-1/2'
							}
						></div>
						<div className={'mx-4'}>OR</div>
						<div
							className={
								'border-b border-x-0 border-t-0 border-gray-400 border-solid w-1/2'
							}
						></div>
					</div>

					<div
						ref={ref}
						className={ClassString({
							static: 'h-[44px] w-[235px] flex justify-center items-center',
							dynamic: { hidden: loadingGoogleSSO },
						})}
					></div>

					<div
						className={ClassString({
							static: 'h-[44px] w-[235px] flex justify-center items-center  border border-gray-200 rounded-full',
							dynamic: { hidden: !loadingGoogleSSO },
						})}
					>
						<LoadingSpinner />
					</div>
				</div>

				<div
					className={ClassString({
						static: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none',
						dynamic: { 'opacity-0': !loading },
					})}
				>
					<LoadingIndicator />
				</div>
			</ViewPanel>

			<div
				className={
					'max-w-md absolute sm:static bottom-3 left-1/2 transform sm:transform-none -translate-x-1/2'
				}
			>
				<span className={'mr-2'}>{'New to Tools?'}</span>
				<Link to={'/sign-up'} className={'text-blue-500'}>
					Sign Up
				</Link>
			</div>
		</View>
	);
});

export default SignIn;
