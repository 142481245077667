import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { useDebounce } from '@uidotdev/usehooks';

interface RankingsHourRangeSelectorProps {
	initialDate: Date;
	onChange: (date: Date) => unknown;
}

const RankingsHourRangeSelector = observer(function RankingsHourRangeSelector(
	props: RankingsHourRangeSelectorProps
) {
	const screenBreakpoint = useScreenBreakpoint();

	const [date, setDate] = useState(props.initialDate);
	const dateDebounce = useDebounce(date, 200);

	useEffect(() => {
		props.onChange(date);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateDebounce]);

	const hours = Array.from({ length: 24 }, (_, i) => i);

	const showHourIndicator = (hour: number) => {
		if (screenBreakpoint > ScreenBreakpoint.MD) {
			return true;
		}

		const allowedHours = [0, 6, 12, 18, 23];

		return allowedHours.includes(hour);
	};

	useEffect(() => {
		if (props.initialDate.getTime() !== date.getTime()) {
			setDate(props.initialDate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.initialDate]);

	return (
		<div
			className={
				'w-full flex justify-center items-center flex-col p-6 pb-12'
			}
		>
			<div className={'text-sm italic mb-1'}>Hour</div>

			<input
				type={'range'}
				min={'0'}
				max={'23'}
				value={date.getHours()}
				onChange={(e) => {
					const hour = Number(e.target.value);
					const updatedDate = new Date(date);
					updatedDate.setHours(hour);
					setDate(updatedDate);
				}}
				className={
					'w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer'
				}
				// style={{ '--SliderColor': BrandColors.blue500 }}
			/>
			<div
				className={'relative mt-4'}
				style={{
					width: 'calc(100% - 16px)',
				}}
			>
				<div
					className={
						'border-t-2 border-gray-500 dark:border-gray-400 absolute top-0'
					}
					style={{ width: 'calc(100% + 1px)' }}
				></div>
				{hours.map((hour) => (
					<div
						key={hour}
						className={'absolute'}
						style={{ left: `${(hour / 23) * 100}%` }}
					>
						{showHourIndicator(hour) && (
							<>
								<div
									className={
										'w-px h-2 bg-gray-500 dark:bg-gray-500 absolute -top-2'
									}
								></div>
								<div
									className={
										'text-gray-700 text-sm mt-2 -translate-x-1/2'
									}
								>
									{hour}
								</div>
							</>
						)}
					</div>
				))}
			</div>
		</div>
	);
});

export default RankingsHourRangeSelector;
