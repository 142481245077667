import { makeAutoObservable, runInAction } from 'mobx';

class ScrollStore {
	private _scrollPosition = 0;

	constructor() {
		makeAutoObservable(this);
	}

	setScrollPosition(scrollPosition: number) {
		runInAction(() => {
			this._scrollPosition = scrollPosition;
		});
	}

	get scrollPosition() {
		return this._scrollPosition;
	}
}

const scrollStore = new ScrollStore();
export default scrollStore;
