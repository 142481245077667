import { makeAutoObservable, runInAction } from 'mobx';
import { Ranking, RankingResponseSchema } from '@/schemas/ranking-schema.ts';
import httpFetch from '@services/http-fetch-service.ts';

const rankingApi = `${import.meta.env.VITE_SERVER_URL}/api/stats/ranking`;

class RankingStore {
	private _rankings: Record<number, Record<number, Ranking>> = {};

	constructor() {
		makeAutoObservable(this);
	}

	async getRankingStats(rankingIndex: number, dateTime: number) {
		const res = await httpFetch.GET(
			`${rankingApi}/${rankingIndex}?startTime=${new Date(dateTime).toISOString()}`
		);

		if (res.ok) {
			const rankingResponse = RankingResponseSchema.parse(
				await res.json()
			);
			const ranking = {
				...rankingResponse,
				time: new Date(rankingResponse.time),
			};

			runInAction(() => {
				if (this._rankings[rankingIndex]) {
					this._rankings = {
						...this._rankings,
						[rankingIndex]: {
							...this._rankings[rankingIndex],
							[dateTime]: ranking,
						},
					};
				} else {
					this._rankings = {
						...this._rankings,
						[rankingIndex]: {
							[dateTime]: ranking,
						},
					};
				}
			});
		}
	}

	ranking(rankingIndex: number, dateTime: number): Ranking | undefined {
		if (!this._rankings[rankingIndex]?.[dateTime]) {
			return undefined;
		}

		return this._rankings[rankingIndex][dateTime];
	}
}

const rankingStore = new RankingStore();
export default rankingStore;
