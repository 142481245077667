import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ClassString from '@utils/class-string.ts';
import scrollStore from '@store/scroll-store.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import { useDebounce } from '@uidotdev/usehooks';

interface ScrollProviderProps {
	children: ReactNode;
	center?: boolean;
}

const ScrollProvider = observer(function ScrollProvider(
	props: ScrollProviderProps
) {
	const signInScreen =
		window.location.href.endsWith('sign-in') ||
		window.location.href.endsWith('sign-up');

	const [enableTransition, setEnableTransition] = useState(true);
	const resetTransitionStart = useRef(0);
	const resetTransitionDebounce = useDebounce(
		resetTransitionStart.current,
		1000
	);

	const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const target = event.target as HTMLDivElement;
		scrollStore.setScrollPosition(target.scrollTop);
	};

	useEffect(() => {
		const handleResize = () => {
			if (enableTransition) {
				setEnableTransition(false);
			}
			resetTransitionStart.current = new Date().getTime();
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (resetTransitionDebounce) {
			setEnableTransition(true);
			resetTransitionStart.current = 0;
		}
	}, [resetTransitionDebounce]);

	return (
		<div
			className={ClassString({
				static: 'h-scroll-container overflow-y-scroll py-6 max-w-screen overflow-x-hidden fixed bottom-0 right-0 w-screen',
				dynamic: {
					'flex items-center justify-center': props.center,
					'lg:w-scroll-container':
						navSidebarStore.minimal && !signInScreen,
					'lg:w-scroll-container-expanded':
						!navSidebarStore.minimal && !signInScreen,
					'h-screen p-0': signInScreen,
				},
			})}
			style={{
				transition: enableTransition
					? 'width 400ms cubic-bezier(0.165, 0.84, 0.44, 1)'
					: undefined,
			}}
			onScroll={handleScroll}
		>
			{props.children}
		</div>
	);
});

export default ScrollProvider;
