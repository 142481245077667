import { z } from 'zod';

export interface RankingPosition {
	place_id: number;
	pos: number;
}

export interface Ranking {
	time: Date;
	positions: RankingPosition[];
}

export interface RankingResponse {
	time: string; // Date string
	positions: RankingPosition[];
}

export const RankingPositionSchema: z.ZodType<RankingPosition> = z.object({
	place_id: z.number(),
	pos: z.number(),
});

export const RankingResponseSchema: z.ZodType<RankingResponse> = z.object({
	time: z.string(),
	positions: z.array(RankingPositionSchema),
});
