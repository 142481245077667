import rankingStore from '@store/ranking-store.ts';
import experienceStore from '@store/experience-store.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import hashQueryParamService from '@services/hash-query-param-service.ts';

export interface RankingsLoaderReturn {
	rankingsIndex: number;
}

export async function RankingsLoader(rankingsIndex: number) {
	void experienceStore.loadSlimExperiences();

	const hashParamMap = hashQueryParamService.get();
	const time = hashParamMap.time ?? 0;

	// const url = new URL(requestUrl);
	// const time = url.searchParams.get('time') ?? 0;

	await rankingStore.getRankingStats(rankingsIndex, +time);

	const rankings = rankingStore.ranking(rankingsIndex, +time);
	if (rankings) {
		void thumbnailStore.loadThumbnailsById(
			rankings.positions.map((pos) => pos.place_id),
			ThumbnailType.ICON
		);
	}

	return { rankingsIndex };
}
