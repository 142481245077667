import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import eventStatsStore from '@store/event-stats-store.ts';

interface EventCredentialEventStatsGraphProps {
	experienceId: number;
	title: string;
}

const EventCredentialEventStatsGraph = observer(
	function EventCredentialEventStatsGraph(
		props: EventCredentialEventStatsGraphProps
	) {
		const eventStats = eventStatsStore.getEventStatById(props.experienceId);

		const data = eventStats.map((stat) => [
			stat.date.getTime(),
			stat.num_events,
		]);

		const options: Highcharts.Options = {
			title: {
				text: props.title,
			},
			series: [
				{
					name: 'Events',
					type: 'column',
					data,
					showInLegend: false,
				},
			],
			chart: {
				marginLeft: 45,
			},
			xAxis: {
				type: 'datetime',
			},
			yAxis: {
				title: {
					enabled: false,
				} as Highcharts.YAxisOptions,
				labels: {
					x: -5,
					y: 3,
					reserveSpace: false,
					style: {
						whiteSpace: 'nowrap',
					},
				},
			},
			plotOptions: {
				line: {
					marker: {
						enabled: false,
					},
				},
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: true,
					},
				},
			},
			navigator: {
				enabled: true,
			},
			scrollbar: {
				enabled: true,
				liveRedraw: true,
			},
			rangeSelector: {
				enabled: true,
				selected: 7,
			},
		};

		return (
			<div>
				<div className={'relative'}>
					<Chart
						highcharts={Highcharts}
						options={options}
						disableShareButton
					/>
				</div>
			</div>
		);
	}
);

export default EventCredentialEventStatsGraph;
