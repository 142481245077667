import View from '@/components/view/view';
import { observer } from 'mobx-react-lite';
import { IconEnum } from '@/components/core/icon/icon-enum';
import ViewPanel from '@components/view/view-panel.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { useState } from 'react';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;
const url = `${baseUrl}/rbx-Code-Library/`;

const CodeLibrary = observer(function CodeLibrary() {
	const [loading, setLoading] = useState(true);

	return (
		<View
			viewInfo={{
				title: 'Code Library',
				icon: IconEnum.DESCRIPTION,
				robotNoIndex: true,
			}}
			fullHeight
			hideHeader
		>
			<ViewPanel
				options={{
					fullHeight: true,
					noPadding: true,
					hideOverflow: true,
					fullWidth: true,
				}}
				className={'relative'}
			>
				<iframe
					title="Code Library"
					src={url}
					className={'w-full h-full absolute'}
					onLoad={() => {
						setLoading(false);
					}}
				/>

				{loading && (
					<LoadingIndicator
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-in-front'
						}
					/>
				)}
			</ViewPanel>
		</View>
	);
});

export default CodeLibrary;
