import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import View from '@components/view/view.tsx';
import rankingStore from '@store/ranking-store.ts';
import { useLoaderData } from 'react-router-dom';
import { RankingsLoaderReturn } from '@views/rankings/rankings-loader.ts';
import experienceStore from '@store/experience-store.ts';
import RankingsExperiencesList from '@views/rankings/view-components/rankings-experiences-list.tsx';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import { RankingCategories } from '@/enums/ranking-categories.ts';
import RankingsHourRangeSelector from '@views/rankings/view-components/rankings-hour-range-selector.tsx';
import { useEffect, useState } from 'react';
import useHashQueryParams from '@hooks/use-hash-query-params.tsx';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import Button from '@components/core/button/button.tsx';

const Rankings = observer(function Rankings() {
	const [searchParams, setSearchParams] = useHashQueryParams();

	const { rankingsIndex } = useLoaderData() as RankingsLoaderReturn;

	// const url = new URL(window.location.href);
	const referencedExperience = searchParams.exp;
	const timeParam = searchParams.time;
	const time = timeParam ? +timeParam : 0;
	const [date, setDate] = useState(new Date(time));

	const rankings = rankingStore.ranking(rankingsIndex, time);
	if (rankings) {
		void thumbnailStore.loadThumbnailsById(
			rankings.positions.map((pos) => pos.place_id),
			ThumbnailType.ICON
		);
	}

	const experiences = rankings
		? rankings.positions.map((pos) => {
				return experienceStore.getSlimExperienceById(pos.place_id);
			})
		: [];

	const setNewTime = (date: Date) => {
		setSearchParams({
			...searchParams,
			time: date.getTime().toString(),
		});
	};

	useEffect(() => {
		const startTime = searchParams.time ?? 0;
		if (!startTime) {
			return;
		}
		setDate(new Date(+startTime));
		void rankingStore.getRankingStats(rankingsIndex, +startTime);
	}, [rankingsIndex, searchParams.time]);

	return (
		<View
			viewInfo={{
				title: RankingCategories[rankingsIndex].title,
				icon: IconEnum.RANKING,
			}}
			fullHeight={!experienceStore.slimExperiences.length}
			loading={!experienceStore.slimExperiences.length}
			hideHeader
		>
			<div className={'flex justify-center items-center flex-col w-full'}>
				<div
					className={'w-full flex justify-between items-center gap-2'}
				>
					<Button
						title={'<- Previous Day'}
						onClick={() => {
							const previousDay = new Date(date);
							previousDay.setDate(previousDay.getDate() - 1);
							setNewTime(previousDay);
						}}
					/>
					<div className={'text-2xl text-center'}>
						{`${RankingCategories[rankingsIndex].title} - ${date.toDateString()} ${date.getHours()}:00-${date.getHours() + 1}:00`}
					</div>
					<Button
						title={'Next Day ->'}
						onClick={() => {
							const nextDay = new Date(date);
							nextDay.setDate(nextDay.getDate() + 1);
							setNewTime(nextDay);
						}}
					/>
				</div>

				<RankingsHourRangeSelector
					initialDate={date}
					onChange={setNewTime}
				/>

				<ViewPanel
					loading={!rankings}
					options={{
						size: ViewPanelSizeEnum.HALF,
						noPadding: true,
						noOutline: true,
					}}
				>
					<RankingsExperiencesList
						experiences={experiences}
						referencedExperience={
							referencedExperience
								? +referencedExperience
								: undefined
						}
					/>
				</ViewPanel>
			</div>
		</View>
	);
});

export default Rankings;
