import { useState, useEffect } from 'react';

const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkMobile = () => {
			const mobileUserAgent = /iPhone|iPad|iPod|Android/i.test(
				navigator.userAgent
			);
			setIsMobile(navigator.maxTouchPoints > 0 && mobileUserAgent);
		};

		checkMobile();
	}, []);

	return isMobile;
};

export default useIsMobile;
