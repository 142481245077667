import { observer } from 'mobx-react-lite';
import { reaction } from 'mobx';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import experienceStore from '@store/experience-store.ts';
import GenreTable from '@views/genres/view-components/genre-table.tsx';
import { useLoaderData } from 'react-router-dom';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExperienceTable from '@components/tables/experience-table.tsx';
import { useEffect, useState } from 'react';
import { SlimExperience } from '@/schemas/experience-schema.ts';

const capitalizeWords = (str: string) => {
	return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const Genre = observer(function Genre() {
	const { genre } = useLoaderData() as { genre: string };

	const [initialized, setInitialized] = useState(false);
	const [experiences, setExperiences] = useState<SlimExperience[]>([]);
	const [tableData, setTableData] = useState<
		{ name: string; experiences: SlimExperience[]; mainGenre: string }[]
	>([]);

	useEffect(() => {
		const setGenreData = () => {
			if (initialized) {
				return;
			}

			setExperiences(experienceStore.getExperiencesByGenre(genre));

			const subGenres = experienceStore.getSubGenres(genre);
			setTableData(
				Object.entries(subGenres).map(([subGenre, experiences]) => {
					return {
						name: subGenre,
						experiences,
						mainGenre: genre,
					};
				})
			);

			setInitialized(true);
		};

		const disposer = reaction(
			() => experienceStore.getSubGenres(genre), // Track only this specific value
			() => {
				setGenreData();
			}
		);

		if (experienceStore.slimExperiences.length) {
			setGenreData();
		}

		return () => disposer(); // Cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: capitalizeWords(genre),
				icon: IconEnum.GENRE,
				iconSize: '30px',
				breadCrumbs: [{ title: 'Genres', route: '/genres' }],
			}}
			loading={!initialized}
			hideHeader
		>
			{!!tableData.length && (
				<ViewPanel className={'max-w-screen-lg'}>
					<GenreTable
						genres={tableData}
						genreColumnHeader={'Sub-Genres'}
						subGenres
					/>
				</ViewPanel>
			)}

			<ViewPanel>
				<ExperienceTable experiences={experiences} />
			</ViewPanel>
		</View>
	);
});

export default Genre;
