import { makeAutoObservable, runInAction } from 'mobx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';

export interface ViewInfo {
	title: string;
	icon?: IconEnum;
	iconSize?: string;
	breadCrumbs?: {
		title: string;
		route?: string;
	}[];
	metaTagDescription?: string;
	metaTagImageUrl?: string;
	robotNoIndex?: boolean;
}

class ViewStore {
	private _viewInfo: ViewInfo | undefined;

	// Can be used to keep track of asynchronous loading in the view
	private _loadingViewData = false;

	constructor() {
		makeAutoObservable(this);
	}

	resetViewInfo() {
		runInAction(() => {
			this._viewInfo = undefined;
		});
	}

	setViewInfo(viewInfo: ViewInfo | undefined) {
		runInAction(() => {
			this._viewInfo = viewInfo;
		});
	}

	get viewInfo() {
		return this._viewInfo;
	}

	set loadingViewData(loading: boolean) {
		runInAction(() => {
			this._loadingViewData = loading;
		});
	}

	get loadingViewData() {
		return this._loadingViewData;
	}
}

const viewStore = new ViewStore();
export default viewStore;
