import experienceStore from '@store/experience-store.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import viewStore from '@store/view-store.ts';
import robuxPriceHistoryStore from '@store/robux-price-history-store.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';

export interface ExperienceDetailsLoaderReturn {
	experience: FullExperience;
}

export async function ExperienceDetailsLoader(experienceId: string) {
	viewStore.loadingViewData = true;
	void thumbnailStore.loadAllThumbnails(+experienceId);
	void robuxPriceHistoryStore.loadExperienceRobuxPriceHistory(+experienceId);

	let experience = experienceStore.getFullExperienceById(+experienceId);

	if (experience) {
		// Load potential updates for the full experience
		void experienceStore.getFullExperience(experienceId);
		return { experience };
	}

	const res = await experienceStore.getFullExperience(experienceId);

	experience = experienceStore.getFullExperienceById(+experienceId);

	if (!res.ok && !experience) {
		throw new Response(null, { status: res.status });
	}

	return { experience };
}
